<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Manual Deposit</h5>
      <div class="row">
        <div class="col">
          <div class="alert alert-warning" v-show="showManualDepositInfo">
            <p class="text-dark">
              Terkadang beberapa transaksi terlewat dari proses auto
              singkronisasi yang mungkin ada faktor eksternal diluar aplikasi
              kami.
            </p>
            <p class="text-dark">
              Kamu dapat memasukkan transaksi hash pada input dibawah ini.
              <span
                style="
                  font-weight: bold;
                  cursor: pointer;
                  text-decoration: underline;
                  color: blue;
                "
                @click="showManualDepositFormAction"
              >
                here
              </span>
            </p>
          </div>

          <form
            @submit.prevent="submitFormManualDeposit"
            v-show="showManualDepositForm"
          >
            <div class="form-row mt-4">
              <div class="col-md-12">
                <label>Tx Hash:</label>
                <input
                  type="text"
                  v-model="manualDeposit.txhash"
                  class="form-control"
                />
              </div>

              <div class="col-md-12 mt-2">
                <button type="submit" class="btn btn-light">Submit</button>
                <a href="#" @click="showManualDepositInfoAction"> Cancel </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    currency: {
      type: String,
      required: true,
    },
    activeCurrencyObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      ethereumAddress: "",

      showManualDepositInfo: true,
      showManualDepositForm: false,

      manualDeposit: {
        txhash: "",
        chain_contract: "",
      },
    };
  },

  async mounted() {
    // console.log('manual BNB');
  },

  methods: {
    showManualDepositFormAction() {
      var self = this;
      self.showManualDepositInfo = false;
      self.showManualDepositForm = true;
    },
    showManualDepositInfoAction() {
      var self = this;
      self.showManualDepositInfo = true;
      self.showManualDepositForm = false;
    },

    async submitFormManualDeposit() {
      var self = this;
      var res;

      // console.log('self.activeCurrencyObj', JSON.stringify(self.activeCurrencyObj));

      // deteksi apakah ini adalah BNB
      if (
        self.activeCurrencyObj.ccurrency_kind == "evm_coin" &&
        self.activeCurrencyObj.ccurrency_symbol == "BNB" &&
        self.currency == "BNB"
      ) {
        res = await thestore.postBnbManualDeposit(
          self.token,
          self.manualDeposit.txhash
        );
        if (res.success == true) {
          alert("Manual deposit success");
          self.$router.go();
        } else {
          alert(res.msg);
        }
      }
    },
  },
};
</script>
