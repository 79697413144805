<template>
  <div class="home_container">
    <TopNav />

    <div class="landing-hero">
      <div class="container">
        <div class="row landing-text-box">
          <div class="col-md-8">
            <h2>PINTU GERBANG ANDA MENUJU DUNIA ASET DIGITAL</h2>
            <p>
              Di dunia yang semakin digital saat ini, akses terhadap aset
              digital seperti cryptocurrency dan token menjadi lebih penting
              daripada sebelumnya.
            </p>
            <div class="input-group" id="auth_menu_button">
              <router-link
                to="/auth/register"
                class="btn btn-lg btn-outline-orange"
              >
                DAFTAR
              </router-link>
              <router-link
                to="/auth/login"
                class="btn btn-lg btn-outline-yellow"
              >
                MASUK
              </router-link>
            </div>
          </div>

          <div class="offset-md-1 col-md-3">
            <!-- TradingView Widget BEGIN -->
            <div
              class="tradingview-widget-container tradingview-tecnical-analys"
            >
              <div class="tradingview-widget-container__widget"></div>
            </div>
            <!-- TradingView Widget END -->
          </div>
        </div>
      </div>
    </div>

    <div class="container popular-markets">
      <h2>Pasar Terpopuler</h2>

      <MarketTable />
    </div>

    <div class="landing-info-one landing-info-one-bg mtb100">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2>Mengapa harus menggunakan Bursa Kripto Indonesia</h2>
            <p>
              Ketika kita bekerja bersama, pintu keuntungan akan terbuka lebar.
            </p>
            <ul>
              <li>
                <i class="icon ion-ios-checkmark-circle"></i> Mudah untuk
                digunakan
              </li>
              <li>
                <i class="icon ion-ios-checkmark-circle"></i> Biaya rendah
              </li>
              <li>
                <i class="icon ion-ios-checkmark-circle"></i> Transparansi
              </li>
            </ul>
          </div>
          <div class="col-md-6">
            <img src="/images/copibit-robo.jpeg" alt="" />
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import MarketTable from "@/components/global/MarketTable.vue";

export default {
  components: {
    TopNav,
    Footer,
    MarketTable,
  },

  data() {
    return {
      themain,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected");
    },

    market_update: function (data) {
      var self = this;

      var market = data.market;

      self.emitter.emit("fe-market_update", {
        data: market,
      });
    },
  },

  async beforeMount() {
    var self = this;

    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  mounted() {
    var self = this;

    if (self.token) {
      var selected = document.getElementById("auth_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }
  },
};
</script>

<style scoped>
.home_container {
  position: relative;
  height: 100vh;
}
.landing-hero {
  width: 100%;
  height: 75vh; /* vh stands for viewport height */
  background-image: url("/images/hero-background-04.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px;
}

.landing-text-box {
  padding-top: 18%;
}

.btn-outline-orange {
  color: #fff;
  padding: 12px 30px;
  border: 1px solid #fff;
  margin-right: 20px;
  box-shadow: 0 0 15px #ff666d;
  /* background-color: #ff666d; */
}

.btn-outline-yellow {
  color: #fff;
  padding: 12px 30px;
  border: 1px solid #fff;
  margin-right: 20px;
  box-shadow: 0 0 15px #ffc070;
  /* background-color: #ffc070; */
}

.landing-hero p {
  font-size: 18px;
}

.popular-markets h2 {
  margin: 50px 0px 50px 0px;
  color: #fff;
}

@media only screen and (max-width: 991px) {
  .landing-text-box {
    padding-top: 150px;
  }
  .landing-hero h2 {
    font-size: 30px !important;
  }
  .landing-hero p {
    font-size: 15px;
  }
  .btn-outline-orange,
  .btn-outline-yellow {
    padding: 8px 20px;
    margin-right: 15px;
    font-size: 1rem;
  }
}
</style>
