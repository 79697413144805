<!-- 
  halaman ini hanya bisa diakses ketika status kyc approve 
  dan agreement masih false
-->
<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <!-- bagian text agreement -->
            <div class="row">
              <div
                class="col-12"
                style="background-color: #fff; padding: 15px; max-width: 900px"
              >
                <AgreementText :user="user" />
              </div>
            </div>

            <!-- bagian form agreement -->
            <div class="row mt-4">
              <div class="btn btn-primary" @click="btnAgreementClicked">
                Saya menyetujui Surat Perjanjian diatas
              </div>
            </div>
          </div>

          <!-- modal informasi -->
          <div
            class="modal fade text-dark"
            tabindex="-1"
            aria-hidden="true"
            v-bind:id="`info-modal`"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" style="color: #000">Informasi</h5>
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    @click="themain.closeModal(`info-modal`)"
                  >
                    x
                  </button>
                </div>
                <div class="modal-body">
                  <p>Selamat, proses KYC Anda telah disetujui.</p>
                  <p>
                    Sesuai dengan PERATURAN BADAN PENGAWAS PERDAGANGAN BERJANGKA
                    KOMODITI NOMOR 8 TAHUN 2021 TENTANG PEDOMAN PENYELENGGARAAN
                    PERDAGANGAN PASAR FISIK ASET KRIPTO (CRYPTO ASSET) DI BURSA
                    BERJANGKA pasal 24, PT Bursa Kripto Indonesia membuat surat
                    perjanjian terlampir pada halaman ini.
                  </p>
                  <p>
                    Surat Perjanjian Pelanggan Aset kripto ini juga terlampir
                    pada email yang Anda daftarkan.
                  </p>
                  <p>
                    Mohon dibaca surat perjanjian dibawah ini dan tekan saya
                    menyetujui perjanjian pada bagian paling bawah.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
// import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import AgreementText from "@/components/global/AgreementText.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    AgreementText,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;

      // cegah user ini mengakses jika belum di approve kyc
      if (res.orang_kyc_status == "approve" && res.orang_agreement == false) {
        themain.openModal("info-modal");
      } else {
      }
    } else {
    }
  },

  methods: {
    async btnAgreementClicked() {
      var self = this;
      var res = await thestore.postAgreementUserAgree(self.token);
      // console.log("res agreement", res);
      self.$router.push("/settings/profile");
    },
  },
};
</script>

<style scoped></style>
