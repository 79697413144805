<template>
  <div class="idr_wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <HeadBalance
              :currency="currency"
              :activeCurrencyObj="ccurrencyObj"
            />

            <ul class="nav nav-pills mb-3">
              <li class="nav-item">
                <router-link
                  class="nav-link deposit-head text-white"
                  :to="`/settings/wallets/idr_deposit`"
                >
                  Deposit
                </router-link>
              </li>
              <li class="nav-item">
                <a class="nav-link withdraw-head active text-white">
                  Withdraw
                </a>
              </li>
            </ul>

            <div class="card">
              <div v-if="bank?.ac_number != null" class="card-body">
                <div class="form-row mt-4">
                  <div class="col-md-12 mb-4">
                    <label>Bank :</label>
                    <input
                      type="text"
                      v-model="bank.name"
                      class="form-control"
                      readonly
                    />
                  </div>

                  <div class="col-md-12 mb-4">
                    <label>Nama Akun :</label>
                    <input
                      type="text"
                      v-model="bank.ac_name"
                      class="form-control"
                      readonly
                    />
                  </div>

                  <div class="col-md-12 mb-4">
                    <label>Nomor Akun :</label>
                    <input
                      type="text"
                      v-model="bank.ac_number"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>

                <form
                  @submit.prevent="
                    user.two_fa_is_active
                      ? submitWithdrawal()
                      : toggleModalOtp()
                  "
                >
                  <div class="form-row">
                    <div class="col-md-12 mb-4">
                      <label>Jumlah :</label>
                      <input
                        type="number"
                        v-model="wd.amount"
                        class="form-control"
                        @blur="calcAfterFee"
                      />
                    </div>

                    <div class="col-md-12 mb-1" style="color: #fff7ef">
                      Terima: {{ after_fee }} IDR
                    </div>
                    <div class="col-md-12 mb-4" style="color: #fff7ef">
                      Biaya: {{ total_fee }} IDR
                    </div>

                    <!-- dengan OTP jika 2FA tidak aktif -->
                    <!-- <div
                      class="d_otp col-md-12 mb-4"
                      v-if="user.two_fa_is_active == false"
                    >
                      <div>
                        <label>OTP:</label>
                        <input
                          type="text"
                          v-model="wd.otp"
                          class="form-control"
                        />
                      </div>
                      <div class="btn_otp">
                        <BtnRequestOtp v-if="user.two_fa_is_active == false" />
                      </div>
                    </div> -->

                    <!-- dengan OTP jika 2FA tidak aktif -->
                    <div class="col-md-12" v-if="user.two_fa_is_active == true">
                      <label>Google Secret:</label>
                      <input
                        type="text"
                        v-model="wd.google_secret"
                        class="form-control"
                      />
                    </div>

                    <div class="mt-3 d-flex">
                      <button type="submit" class="btn btn-gradient mr-2">
                        Withdraw
                      </button>

                      <ModalOtp :show="isModalOtpShow" @getOtpEvent="getOtp" />
                    </div>
                  </div>
                </form>
              </div>

              <!-- no bank acccount -->
              <div v-else class="add_bank">
                <div class="ad_bank-c">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="4em"
                    height="4em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="white"
                      d="M2 8V6.25A3.25 3.25 0 0 1 5.25 3h2.879a2.25 2.25 0 0 1 1.59.659l1.531 1.53L8.659 7.78a.75.75 0 0 1-.53.22zm0 1.5v8.25A3.25 3.25 0 0 0 5.25 21h6.772A6.5 6.5 0 0 1 22 12.81V8.75a3.25 3.25 0 0 0-3.25-3.25h-5.69L9.72 8.841a2.25 2.25 0 0 1-1.591.659zm21 8a5.5 5.5 0 1 0-11 0a5.5 5.5 0 0 0 11 0m-5 .5l.001 2.503a.5.5 0 1 1-1 0V18h-2.505a.5.5 0 0 1 0-1H17v-2.5a.5.5 0 1 1 1 0V17h2.497a.5.5 0 0 1 0 1z"
                    />
                  </svg>

                  <div @click="handleAddBank" class="add_bank-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"
                      />
                    </svg>
                    <span>Tambah Rekening</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- card withdraws -->
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Daftar Withdraw</h5>
                <div class="wallet-history">
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-left">No.</th>
                        <th class="text-left">Date</th>
                        <th class="text-right">Jumlah</th>
                        <th class="text-left">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in withdraws" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                          {{
                            moment(item.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{ parseFloat(item.wdidr_amount).numberFormat(2) }}
                        </td>
                        <td class="text-left">
                          <span
                            class="text-success"
                            v-if="item.wdidr_status === 'COMPLETED'"
                            >{{ item.wdidr_status }}</span
                          >
                          <span
                            class="text-warning"
                            v-if="item.wdidr_status === 'PENDING'"
                            >{{ item.wdidr_status }}</span
                          >
                          <span
                            class="text-danger"
                            v-if="item.wdidr_status === 'FAILED'"
                            >{{ item.wdidr_status }}</span
                          >

                          <span
                            class="text-danger"
                            v-if="item.wdidr_status === 'CANCEL'"
                            >{{ item.wdidr_status }}</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import HeadBalance from "@/components/Settings/Wallets/HeadBalance.vue";
// import BtnRequestOtp from "@/components/global/BtnRequestOtp.vue";
import ModalOtp from "@/components/modals/ModalOtp.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    HeadBalance,
    // BtnRequestOtp,
    ModalOtp,
  },
  data() {
    return {
      themain,
      moment,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      currency: "IDR",
      ccurrencyObj: {
        ccurrency_config: {
          address_view: "",
          deposit_info_view: "",
          deposit_manual_view: "",
        },
        wd_config: [],
        ccurrency_withdraw_fee: 0,
      },
      isModalOtpShow: false,

      user: {},

      bank: null,

      after_fee: 0,
      xendit_wd_fee: 0,
      total_fee: 0,
      wd: {
        amount: 0,
        otp: "",
        google_secret: "",
      },

      withdraws: [],
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected -bal");
    },

    ubal: function (data) {
      var self = this;
      var obj = JSON.parse(data);
      obj.forEach(function (el) {
        // jika ada yang mengarah kepada kita, maka kita akan ubah data
        if (parseInt(self.usid) == el.u) {
          // disini kita sudah dapat 1 data saja
          // seperti: {"u":3,"BTC":"9.982423893170436","IDR":"999574426.8375854"}
          var balObjSocket = el;

          // iterasi semua balances
          self.balances.forEach(function (elbal, indexBalances) {
            // perulangan semua map dari balanceObjSocket
            // iterasi dari key: ['u', 'BTC', 'IDR']
            Object.keys(balObjSocket).forEach(function (balSocketKey) {
              if (elbal.ccurrency_symbol == balSocketKey) {
                self.balances[indexBalances].saldo_value =
                  balObjSocket[balSocketKey];
              }
            });
          });
        }
      });
    },
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    } else {
      self.$router.push("/");
    }
  },

  async mounted() {
    var self = this;
    await self.getCcurrencyData();
    await self.getUserData();
    await self.getMyBank();
    await self.getWithdraws();
    await self.getXenditFee();
  },

  methods: {
    async getUserData() {
      var self = this;

      // ambil data dia ketika login
      if (self.token != null && self.token != "") {
        var res = await thestore.getMyProfileData(self.token);
        self.user = res;
      } else {
        self.$router.push("/");
      }
    },

    async getCcurrencyData() {
      var self = this;
      var filter_rules = [
        { field: "ccurrency_symbol", op: "equal", value: self.currency },
      ];
      var res = await thestore.getCurrencies(1, 1, "id", "desc", filter_rules);
      self.ccurrencyObj = res.datas[0];
    },

    async getMyBank() {
      var self = this;
      var res = await thestore.getMyBank(self.token);
      // console.log("res", res);
      self.bank = res;
    },

    async getXenditFee() {
      var self = this;
      var res = await thestore.getWalletIdrXenditWdFee(self.token);
      // console.log("res xendit wd fee", res);
      self.xendit_wd_fee = res.data;
    },

    calcAfterFee() {
      var self = this;

      // untuk rupiah, biaya adalah dalam %
      const feePercent =
        (parseFloat(self.ccurrencyObj.ccurrency_withdraw_fee) / 100) *
        parseFloat(self.wd.amount);

      // hitung total biaya yang ditanggung oleh user
      // total biaya ini kombinasi biaya dalam persen ditambah dengan biaya fix dari xendit
      const biaya = feePercent + self.xendit_wd_fee;

      // simpat total biaya supaya bisa ditampilkan di view
      self.total_fee = biaya;

      // user terima bersih
      self.after_fee = parseFloat(self.wd.amount) - biaya;
    },

    async submitWithdrawal() {
      // validasi untuk setiap kolom form harus diisi jika tidak fungsi akan break
      let isValid = this.formValidate();

      if (!isValid) {
        return;
      }

      var self = this;
      const userId = Cookies.get("usid");

      const datas = {
        amount: self.wd.amount,
        description: "wd",
        bank_code: self.bank?.name,
        account_holder_name: self.bank?.ac_name,
        account_number: self.bank?.ac_number,
        user_id: userId,
        otp: self.wd.otp,
      };

      try {
        const res = await thestore.postWithdrawIDR(datas, self.token);

        if (res.success) {
          toast.success("Witdhraw berhasil di proses");
          self.getWithdraws();
          this.toggleModalOtp();
        } else {
          toast.warning(res.msg);
        }
      } catch (error) {
        if (error.response) {
          // Respons error dari server
          if (error.response.status === 400) {
            toast.error(error.response.data.message);
            console.error("Error 400:", error.response.data.message);
          } else {
            // toast.error(error.response.data);
            console.error("Error:", error.response.data);
          }
        } else if (error.request) {
          // Request dikirim tetapi tidak ada respons
          toast.error(error.request);
          console.error("No response received:", error.request);
        } else {
          // Terjadi kesalahan saat menyiapkan request
          toast.error(error.message);
          console.error("Error setting up request:", error.message);
        }
      }

      // var res = await thestore.postWalletsIdrWithdraw(
      //   self.token,
      //   self.wd.amount,
      //   self.wd.otp,
      //   self.wd.google_secret
      // );
      // if (res.success == true) {
      //   alert("Success");
      //   self.wd.otp = "";
      //   self.wd.google_secret = "";
      //   self.wd.amount = 0;
      // } else {
      //   alert(res.msg);
      // }

      // self.$router.go();
    },

    async getWithdraws() {
      var self = this;
      var filter_rules = [];
      var res = await thestore.getWalletsIdrWithdraws(
        self.token,
        1,
        10,
        "id",
        "desc",
        filter_rules
      );
      self.withdraws = res.datas;
    },

    handleAddBank() {
      window.location.href = "/settings/bank?add=bank";
    },

    toggleModalOtp() {
      // validasi untuk setiap kolom form harus diisi jika tidak fungsi akan break
      let isValid = this.formValidate();

      if (!isValid) {
        return;
      }

      // toggle untuk modal OTP jika 2FA disable
      if (!this.user.two_fa_is_active)
        this.isModalOtpShow = !this.isModalOtpShow;
    },

    getOtp(code) {
      this.wd.otp = code;
      this.submitWithdrawal();
    },

    formValidate() {
      if (!this.wd.amount) {
        toast.warning("Kolom tidak boleh kosong");
        return false;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.idr_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mtb15 {
  min-height: 1000px !important;
}

.add_bank {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ad_bank-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add_bank-btn {
  cursor: pointer;
  color: #708cc7;
}
.d_otp {
  display: flex;
  align-items: center;
  gap: 10px;
}
.btn_otp {
  margin-top: 30px;
}
.settings .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #009b67 !important;
}

@media only screen and (max-width: 994px) {
  .mtb15 {
    height: 1300px;
  }
}
</style>
