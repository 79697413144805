<template>
  <div class="card">
    <div class="card-body">
      <h5 class="card-title">Informasi Token</h5>
      <div class="row">
        <p class="col text-light">Kami mendukung deposit melalui EVM:</p>

        <!-- info token -->
        <div class="col-md-12 text-light">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="text-right">Chain ID</th>
                <!-- <th class="text-left">Chain Name</th> -->
                <th class="text-left">Contract Address</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in evm_tokens" :key="item.id">
                <td align="right">
                  {{ item.chain_id }}
                </td>
                <!-- <td>
                  {{ item.chain_name }}
                </td> -->
                <td>
                  {{ item.contract_address }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    activeCurrencyObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      evm_tokens: [],
    };
  },

  async mounted() {
    var self = this;

    self.getEvmTokenAddress();
  },

  methods: {
    async getEvmTokenAddress() {
      var self = this;
      var res = await thestore.getEvmTokensByCcurrencyId(
        self.activeCurrencyObj.id
      );
      self.evm_tokens = res;
    },
  },
};
</script>
