<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <div class="row">
          <div class="col alert alert-info">
            <h1>{{ pesan }}</h1>

            <router-link to="/auth/login" class="primary-link">
              Silahkan masuk disini
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      pesan: "",
    };
  },

  async beforeMount() {
    var self = this;
    var res = await thestore.postAuthEmailVerification(
      self.$route.query.verification_code
    );
    if (res.success == true) {
      self.pesan = res.msg;
    } else {
      alert(res.msg);
      self.$router.push("/auth/login");
    }
  },
};
</script>
