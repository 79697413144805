<template>
  <div class="bg-dark text-white x-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <img
            src="/images/logo-bki-01.png"
            alt="bki-logo"
            style="height: 42px; margin-bottom: 34px; margin-right: 7px"
          />
          <span style="font-size: 32px">Bursa Kripto Indonesia</span>
          <p class="x-footer-web-info">
            Pintu Gerbang Anda Menuju Dunia Aset Digital
          </p>
        </div>
        <div class="col-lg-4">
          <h5>Tautan</h5>
          <div>
            <ul class="x-pages">
              <!-- <li>
                <router-link to="/p/faq" class="text-white"> FAQ </router-link>
              </li> -->
              <li>
                <router-link to="/pages/term-and-conditions" class="text-white">
                  Syarat dan Ketentuan
                </router-link>
              </li>
              <li>
                <router-link to="/pages/privacy-policy" class="text-white">
                  Kebijakan Privasi
                </router-link>
              </li>
              <router-link to="/pages/contact-us" class="link_f link_g">
                Hubungi Kami
              </router-link>
            </ul>
          </div>
        </div>
        <div class="col-lg-4">
          <h5>Terdaftar dan Diawasi</h5>
          <div class="row mb-4 ml-2 mt-4">
            <img
              src="/images/bappebti-logo.png"
              width="170"
              alt="bappeti logo"
              class="mr-4"
            />
            <!-- <img
              src="/images/iso-27001-logo.png"
              width="70"
              alt="iso 27001 logo"
            /> -->
          </div>
        </div>
      </div>

      <div class="row x-footer-bottom-section">
        <p class="p-4">
          <span style="font-weight: bold">PERINGATAN</span> <br />
          Perdagangan aset kripto memiliki risiko yang tinggi. Penting untuk
          menggunakan pertimbangan yang matang dalam mengambil keputusan untuk
          membeli atau menjual aset Kripto Anda. Bursa Kripto Indonesia tidak
          memaksa pengguna untuk membeli dan menjual aset Kripto, dan semua
          keputusan untuk membeli atau menjual aset Kripto adalah pilihan
          independen yang dibuat oleh pengguna dalam jarak yang wajar dan tanpa
          paksaan. Produk yang diperdagangkan bukan milik Bursa Kripto
          Indonesia; oleh karena itu, Bursa Kripto Indonesia tidak bertanggung
          jawab atas nilai transaksi yang sedang berlangsung dan nilai akhir.
        </p>
        <div class="col">@ 2024 PT Bursa Kripto Indonesia</div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules

// applibs
import themain from "@/applibs/themain.js";

export default {
  components: {},
  data() {
    return {
      themain,
    };
  },

  async mounted() {},

  methods: {},
};
</script>

<style scoped>
.x-footer {
  padding-top: 46px;
  padding-bottom: 20px;
  margin-top: 60px;
  width: 100%;
  /* height: 100%; */
  /* position: absolute;
  bottom: 0; */
}
.link_g {
  color: #fff;
}
ul.x-pages {
  list-style-type: none;
  padding-left: 0px;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight: 300;
}
ul.x-pages a {
  text-decoration: none;
}

.x-footer-bottom-section {
  border-top: 1px solid #fff;
  padding-top: 10px;
}

.x-footer-web-info {
  font-size: 0.8rem;
}
</style>
