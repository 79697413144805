<template>
  <div>
    <table class="table my-open-order-table">
      <thead>
        <tr>
          <th>Waktu</th>
          <th>Beli/Jual</th>
          <th>Harga</th>
          <th>{{ simbol_a }}</th>
          <th>{{ simbol_b }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in myobooks" :key="item">
          <!-- time -->
          <td>
            {{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
          </td>

          <!-- Buy/Sell -->
          <td>
            <span v-if="item.market_place == 'Limit Buy'" class="green">
              Beli
            </span>
            <span v-if="item.market_place == 'Limit Sell'" class="red">
              Jual
            </span>
          </td>

          <!-- Price -->
          <td>
            <span v-if="item.market_place == 'Limit Buy'" class="green">
              {{
                parseFloat(item.market_position).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </span>
            <span v-if="item.market_place == 'Limit Sell'" class="red">
              {{
                parseFloat(item.market_position).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </span>
          </td>

          <!-- simbol_a -->
          <td>
            <div v-if="item.market_place == 'Limit Buy'">
              {{
                parseFloat(item.market_result_including_fee).numberFormat(
                  currencyAObj.decimal_display
                )
              }}
            </div>
            <div v-else>
              {{
                parseFloat(item.market_amount).numberFormat(
                  currencyAObj.decimal_display
                )
              }}
            </div>
          </td>

          <!-- simbol_b -->
          <td>
            <div v-if="item.market_place == 'Limit Buy'">
              {{
                parseFloat(item.market_amount).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </div>
            <div v-else>
              {{
                parseFloat(item.market_result_including_fee).numberFormat(
                  currencyBObj.decimal_display
                )
              }}
            </div>
          </td>

          <td>
            <button
              class="btn btn-outline-info btn-sm"
              @click="themain.openModal(`myorders-${item.id}-modal`)"
            >
              Batal
            </button>

            <div
              class="modal fade text-dark"
              tabindex="-1"
              aria-hidden="true"
              v-bind:id="`myorders-${item.id}-modal`"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content bg-dark">
                  <div class="modal-header">
                    <h5 class="modal-title text-light">Konfirmasi</h5>
                    <button
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                      @click="themain.closeModal(`myorders-${item.id}-modal`)"
                      :disabled="modalCloseBtnDisabled"
                    >
                      <svg
                        data-v-2cdd5bbc=""
                        width="21"
                        height="21"
                        viewBox="0 0 21 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          data-v-2cdd5bbc=""
                          cx="10.5"
                          cy="10.5"
                          r="10.5"
                          fill="#F6465D"
                        ></circle>
                        <path
                          data-v-2cdd5bbc=""
                          d="M16.275 14.122C16.275 14.3694 16.1884 14.5798 16.0152 14.753L14.753 16.0152C14.5798 16.1884 14.3694 16.275 14.122 16.275C13.8745 16.275 13.6641 16.1884 13.4909 16.0152L10.7625 13.2867L8.03409 16.0152C7.86086 16.1884 7.6505 16.275 7.40303 16.275C7.15556 16.275 6.9452 16.1884 6.77197 16.0152L5.50985 14.753C5.33662 14.5798 5.25 14.3694 5.25 14.122C5.25 13.8745 5.33662 13.6641 5.50985 13.4909L8.23826 10.7625L5.50985 8.03409C5.33662 7.86086 5.25 7.6505 5.25 7.40303C5.25 7.15556 5.33662 6.9452 5.50985 6.77197L6.77197 5.50985C6.9452 5.33662 7.15556 5.25 7.40303 5.25C7.6505 5.25 7.86086 5.33662 8.03409 5.50985L10.7625 8.23826L13.4909 5.50985C13.6641 5.33662 13.8745 5.25 14.122 5.25C14.3694 5.25 14.5798 5.33662 14.753 5.50985L16.0152 6.77197C16.1884 6.9452 16.275 7.15556 16.275 7.40303C16.275 7.6505 16.1884 7.86086 16.0152 8.03409L13.2867 10.7625L16.0152 13.4909C16.1884 13.6641 16.275 13.8745 16.275 14.122Z"
                          fill="#FAFAFA"
                        ></path>
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body text-light">
                    <div class="warning_box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="21"
                        height="98"
                        viewBox="0 0 21 98"
                        fill="none"
                      >
                        <path
                          d="M10.2697 6C8.10247 6 6.12041 7.84191 6.26556 10.0041L8.26764 64.0602C8.26764 64.5912 8.47857 65.1004 8.85403 65.4759C9.22949 65.8513 9.73873 66.0622 10.2697 66.0622C10.8007 66.0622 11.3099 65.8513 11.6854 65.4759C12.0609 65.1004 12.2718 64.5912 12.2718 64.0602L14.2739 10.0041C14.419 7.84191 12.437 6 10.2697 6Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.2698 91.2728C12.4812 91.2728 14.2739 89.48 14.2739 87.2686C14.2739 85.0572 12.4812 83.2645 10.2698 83.2645C8.05834 83.2645 6.26562 85.0572 6.26562 87.2686C6.26562 89.48 8.05834 91.2728 10.2698 91.2728Z"
                          stroke="#FF0F18"
                          stroke-width="12"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <h2>Apakah Anda yakin?</h2>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn_secondary"
                      @click="themain.closeModal(`myorders-${item.id}-modal`)"
                      :disabled="modalCloseBtnDisabled"
                    >
                      Tidak
                    </button>
                    <button
                      type="button"
                      class="btn btn_primary"
                      @click="cancelBuyOrSell(item)"
                    >
                      Ya
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
  },
  data() {
    return {
      themain,
      moment,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      simbol_a: "",
      simbol_b: "",

      myobooks: [],

      modalCloseBtnDisabled: false,
    };
  },

  async created() {
    var self = this;

    // disini kita dengarkan fe-sells dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-sells", async function (e) {
      // console.log("e fe-sells", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    // disini kita dengarkan fe-buys dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-buys", async function (e) {
      // console.log("e fe-buys", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    self.emitter.on("fe-myobook-close", async function (e) {
      var nubooks = [];
      self.myobooks.forEach(function (mbook) {
        if (mbook.id != e.data.id) {
          nubooks.push(mbook);
        }
      });
      self.myobooks = nubooks;
    });
  },

  async mounted() {
    var self = this;

    // simbols
    var simbols = self.marketData.pair.split("_");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];

    // jika ada token, mita ambil myorderbook
    if (self.token != null && self.token != "") {
      await self.getMyOrders();
    }
  },

  methods: {
    async getMyOrders() {
      var self = this;
      var filter_rules = [
        {
          field: "market_pair",
          op: "equal-for-pair",
          value: self.marketData.pair,
        },
      ];
      // console.log("self.mytoken", self.token);
      var myobooksRes = await thestore.getMyOrderBooksOpen(
        self.token,
        1,
        20,
        "updated_at",
        "desc",
        filter_rules
      );
      // console.log("myobooksRes", myobooksRes);

      self.myobooks = myobooksRes.datas;
    },

    /**
     * Memasukkan data yang baru atau update yang datang dari emit frontend
     * @param {*} newDataObj
     */
    addOrUpdateMyorders(newDataObj) {
      var self = this;

      var my_orders_new = [];

      // mengganti data yang baru masuk
      var apa_ada = 0;
      self.myobooks.forEach((ele) => {
        if (ele.id == newDataObj.id) {
          my_orders_new.push(newDataObj);
          apa_ada = apa_ada + 1;
        } else {
          my_orders_new.push(ele);
        }
      });

      // jika tidak ada data yang di update, maka dimasukkan saja data baru
      if (apa_ada == 0) {
        my_orders_new.push(newDataObj);
      }

      // ganti myobooks
      self.myobooks = my_orders_new;
    },

    async cancelBuyOrSell(myorderObj) {
      var self = this;

      var res = await thestore.postCancelOrder(self.token, myorderObj.id);
      themain.closeModal(`myorders-${myorderObj.id}-modal`);
      // console.log("res", res);
      if (res.success == true) {
        var olds = self.myobooks;
        var news = [];

        // remove data
        olds.forEach(function (el) {
          if (el.id != myorderObj.id) {
            news.push(el);
          }
        });
        self.myobooks = news;
      } else {
        alert(self.msg);
      }
    },
  },
};
</script>

<style>
.my-open-order-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table tbody tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.my-open-order-table td,
th {
  padding: 5px;
  text-align: right;
}
.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body h2 {
  font-size: 20px;
  margin-top: 10px;
}

.modal-title {
  opacity: 0;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

.warning_box {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  background-color: #343a40;
  border: 4px solid #b6b7b9;

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -170%;
}

.btn_secondary {
  width: 100px;
  height: 30px;
  background-color: #26de81;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
}

.btn_primary {
  width: 100px;
  height: 30px;
  background-color: #ff666d;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffff;
}

.btn-close {
  background: none;
  border: none;
}
</style>
