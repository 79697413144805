<template>
  <div>
    <TopNav />

    <div class="vh-100 d-flex justify-content-center">
      <div class="form-access my-auto">
        <div>
          <span>Lupa Password</span>
          <div class="form-group">
            <input
              type="email"
              class="form-control"
              placeholder="Alamat Email"
              v-model="email"
            />
          </div>
          <div class="form-group">
            <VueRecaptcha
              ref="recaptcha"
              @verify="onVerify"
              :sitekey="recapSiteKey"
            ></VueRecaptcha>
          </div>
          <button
            @click="submitForm"
            v-if="!isLoading"
            type="submit"
            class="btn btn-gradient"
          >
            Kirim
          </button>
          <button v-else class="btn btn-gradient" disabled>Loading...</button>
        </div>
        <h2>
          Apakah Anda ingat kata sandi Anda?
          <router-link to="/auth/login" class="primary-link">
            Klik di sini
          </router-link>
        </h2>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import { VueRecaptcha } from "vue-recaptcha";
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    VueRecaptcha,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      email: "",
      recapSiteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken: "",
      isLoading: false,
    };
  },

  async mounted() {
    var self = this;

    if (self.token) {
      self.$router.push("/");
    }
  },

  methods: {
    async submitForm() {
      var self = this;

      self.isLoading = true;
      if (self.recaptchaToken != "") {
        var res = await thestore.postAuthForgotPassword(
          self.email,
          self.recaptchaToken
        );

        if (res.success == true) {
          toast.success(res.msg);
          self.$router.push("/");
        } else {
          toast.warning(res.msg);
          if (res.msg == "Wrong captcha") {
            self.$router.go();
          }
        }
        self.isLoading = false;
      }
    },

    onVerify(response) {
      var self = this;
      if (response) {
        // User input is valid
        self.recaptchaToken = response;
      } else {
        // User input is invalid
        self.recaptchaToken = "";
      }
    },
  },
};
</script>
