<template>
  <header class="dark-bb">
    <nav
      :class="`navbar navbar-expand-lg ${fixedTop} ${navbarScrolled} ${
        width < 992 && 'bg_color'
      }`"
    >
      <router-link class="navbar-brand" to="/">
        <img
          src="/images/logo-bki-01.png"
          alt="bki-logo"
          style="height: 42px"
        />
      </router-link>
      <button
        class="navbar-toggler pt-2"
        type="button"
        @click="themain.toggle('headerMenu')"
      >
        <i class="icon ion-md-menu text-light"></i>
      </button>

      <div class="collapse navbar-collapse pt-3 pt-lg-0" id="headerMenu">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="/" role="button"> Beranda </a>
          </li>
          <li class="nav-item">
            <router-link to="/markets" class="nav-link"> Pasar </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="themain.defaultMarketPair" class="nav-link">
              Pertukaran
            </router-link>
          </li>

          <li class="nav-item" v-if="user.orang_kyc_status == 'not approve'">
            <div class="nav_kyc">
              <button>Mulai KYC</button>
              <metamap-button
                ref="button"
                :clientid="themain.metamapClientId"
                :flowid="themain.metamapFlowId"
                :metadata="metadata"
                class="metamap_btn"
              >
              </metamap-button>
            </div>
          </li>

          <!-- jika status kyc approve tetapi belum tandatangani agreement, bisa munculkan button ini -->
          <li
            class="nav-item"
            v-if="
              user.orang_kyc_status == 'approve' &&
              (user.orang_agreement == false || user.orang_agreement == null)
            "
          >
            <router-link
              to="/settings/agreement-sign"
              class="nav_link nav_kyc-update"
              >KYC Update</router-link
            >
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <!-- jika ada token, muncul barisan notif -->
          <li
            class="nav-item dropdown header-custom-icon"
            v-if="token"
            @click="themain.openDropdown('user-notifs')"
            id="user-notifs"
          >
            <div
              class="nav-link dropdown-toggle cursor_pointer px-0 mx-3"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="icon ion-md-notifications"></i>
              <span class="circle-pulse" v-if="notifs_count > 0"></span>
            </div>
            <div class="dropdown-menu">
              <div
                class="dropdown-header d-flex align-items-center justify-content-between"
              >
                <p class="mb-0 mr-4 font-weight-medium">
                  {{ notifs_count }} New Notifications
                </p>
                <div
                  @click="postClearAllNotifs()"
                  class="text-muted cursor_pointer"
                >
                  Clear all
                </div>
              </div>
              <div class="dropdown-body">
                <div
                  class="dropdown-item"
                  v-for="item in notifs"
                  :key="item.id"
                >
                  <div class="icon">
                    <i class="icon ion-md-alert"></i>
                  </div>
                  <div class="content">
                    <p>{{ item.notif_text }}</p>
                    <p class="sub-text text-muted">
                      {{
                        moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- <div class="dropdown-footer d-flex align-items-center justify-content-center">
                <a href="#!">View all</a>
              </div> -->
            </div>
          </li>

          <!-- jika ada token, muncul icon user -->
          <li
            class="nav-item dropdown header-img-icon"
            v-if="token"
            @click="themain.openDropdown('user-menu')"
            v-bind:id="`user-menu`"
          >
            <div
              class="nav-link dropdown-toggle cursor_pointer pl-0 ml-3"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src="/assets/img/avatar.svg" alt="avatar" />
            </div>
            <div class="dropdown-menu">
              <div
                class="dropdown-header d-flex flex-column align-items-center"
              >
                <div class="figure mb-3">
                  <img src="/assets/img/avatar.svg" alt="" />
                </div>
                <div class="info text-center">
                  <p class="name font-weight-bold mb-0">
                    {{ user.orang_name }}
                  </p>
                  <p class="email text-muted mb-3">
                    @{{ user.orang_nickname }}
                  </p>
                </div>
              </div>
              <div class="dropdown-body">
                <ul class="profile-nav">
                  <li class="nav-item">
                    <router-link :to="`/settings/profile`" class="nav-link">
                      <i class="icon ion-md-person"></i>
                      <span>Profil</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="`/settings/wallets`" class="nav-link">
                      <i class="icon ion-md-wallet"></i>
                      <span>Dompet Saya</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      @click="logoutAccount"
                      to="#"
                      class="nav-link red"
                    >
                      <i class="icon ion-md-power"></i>
                      <span>Keluar</span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>

          <!-- jika tidak ada token, muncul login dan register -->
          <li class="nav-item" v-if="!token" id="login_menu_button">
            <router-link
              to="/auth/register"
              class="btn btn-outline-orange"
              style="margin-right: 20px"
            >
              Daftar
            </router-link>
            <router-link
              to="/auth/login"
              class="btn btn-outline-yellow"
              style="margin-right: 20px"
            >
              Masuk
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

export default {
  components: {},
  data() {
    return {
      themain,
      moment,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      user: {},

      scrollPosition: 0,
      width: window.innerWidth,

      navbarScrolled: "",
      fixedTop: "",

      notifs: [],
      notifs_count: 0,

      metadata: "",
    };
  },

  sockets: {
    connect: function () {
      console.log("socket connected - top nav");
    },

    /**
     * SEMUA_HALAMAN
     * Notifikasi KYC diletakkan disini supaya bisa di load dari halaman manapun
     * @param {*} data
     */
    kyc_status: function (data) {
      var self = this;
      if (data.uid == self.usid) {
        // jika disetujui, infokan untuk lihat email persetujuan
        if (data.message == "verified") {
          self.$router.push("/settings/agreement-sign");
        } else if (data.message == "reviewNeeded") {
          alert("KYC Anda sedang dalam proses review");
          self.$router.go();
        } else if (data.message == "rejected") {
          alert(`Status KYC Anda ditolak. Mohon dipastikan NIK Anda belum pernah terdaftar 
          dan pastikan juga NIK yang Anda masukkan terlihat jelas.`);
          self.$router.go();
        }
      }
    },
  },

  mounted() {
    var self = this;

    if (
      self.$router.currentRoute._value.path == "/" ||
      self.$router.currentRoute._value.path == "/auth/login" ||
      self.$router.currentRoute._value.path == "/auth/register" ||
      self.$router.currentRoute._value.path == "/auth/forgot-password" ||
      self.$router.currentRoute._value.path == "/auth/passchange-form" ||
      self.$router.currentRoute._value.path == "/auth/two-factor-auth" ||
      self.$router.currentRoute._value.path ==
        "/auth/email-verification-token-request"
    ) {
      var selected = document.getElementById("login_menu_button");
      if (selected) {
        selected.style.visibility = "hidden";
      }
    }

    if (self.$router.currentRoute._value.path == "/") {
      self.fixedTop = "fixed-top";
    }

    // jika ada token, maka ambil data notifikasi dan juga tambahkan metadata
    if (self.token) {
      self.getMyData();
      self.getMyNotifs();

      const userId = Cookies.get("usid");
      self.metadata = `{"user_id":"${userId}"}`;
    }

    window.addEventListener("scroll", this.updateScrollPosition);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.updateScrollPosition);
  },

  methods: {
    updateScrollPosition() {
      var self = this;
      this.scrollPosition = window.scrollY;

      if (this.scrollPosition > 200 && this.width > 991) {
        self.navbarScrolled = "navbar--scrolled";
      } else {
        self.navbarScrolled = "";
      }
    },

    async logoutAccount() {
      var self = this;

      var res = await thestore.postAuthLogout(self.token);
      if (res.success == true) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      } else {
        alert(res.msg);
      }
    },
    async getMyData() {
      var self = this;
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    },

    async getMyNotifs() {
      var self = this;
      var filter_rules = [{ field: "read_tf", op: "boolean", value: false }];
      var res = await thestore.getNotifs(
        self.token,
        1,
        1,
        "id",
        "desc",
        filter_rules
      );
      self.notifs = res.datas;
      self.notifs_count = res.datas.length;
    },

    async postClearAllNotifs() {
      var self = this;
      await thestore.postClearNotifs(self.token);
      self.notifs_count = 0;
    },
  },
};
</script>

<style scoped>
#dark,
#dark header {
  background-color: transparent;
  background: transparent;
  border: none;
}
.navbar {
  background-color: transparent;
  border: none;
  transition: all 0.3s ease-out;
}

.navbar--scrolled {
  background-color: #131722; /* Replace with desired background color */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: add a box shadow */
}

.nav_kyc {
  position: relative;
}

.nav_kyc button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* color: #dd2730; */
  color: #fff;
  margin-left: -8px;
  margin-top: -3px;

  background: none;
  /* border: 1px solid #dd2730; */
  border: 1px solid #fff;
  border-radius: 3px;
}

.nav_kyc-update {
  color: #fff;
  background: none;
  padding: 3px 5px;
  /* border: 1px solid #dd2730; */
  border: 1px solid #fff;
  border-radius: 3px;
}
.nav_kyc-update:hover {
  color: #fff !important;
}
.navbar-nav {
  align-items: center;
}
.metamap_btn {
  opacity: 0;
  width: 150px;
}

.bg_color {
  background-color: #131722;
}

.cursor_pointer {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .navbar-collapse {
    background-color: #131722;
  }
}
</style>
