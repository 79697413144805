<template>
  <div class="col-lg-12 ctr_trm">
    <h2 class="head_txt">PERATURAN TATA TERTIB PERDAGANGAN</h2>
    <div>
      <br />
    </div>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      DEFINISI
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Sepanjang konteks kalimatnya tidak menentukan lain, istilah atau definisi
      dalam PTT memiliki arti sebagai berikut:
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Badan Pengawas Perdagangan Berjangka Komoditi yang selanjutnya disebut
        Bappebti adalah lembaga pemerintah yang tugas pokoknya melakukan
        pembinaan, pengaturan, pengembangan, dan pengawasan Perdagangan
        Berjangka.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Bursa Berjangka adalah badan usaha yang menyelenggarakan dan menyediakan
        sistem dan/atau sarana untuk kegiatan jual beli Komoditi berdasarkan
        Kontrak Berjangka, Kontrak Derivatif Syariah, dan/atau Kontrak Derivatif
        lainnya.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Anggota Bursa Berjangka adalah Pihak yang mempunyai hak untuk
        menggunakan sistem dan/atau sarana Bursa Berjangka dan hak untuk
        melakukan transaksi Kontrak Berjangka, Kontrak Derivatif Syariah,
        dan/atau Kontrak Derivatif lainnya sesuai dengan peraturan dan tata
        tertib Bursa Berjangka.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Lembaga Kliring Berjangka dan Penjaminan Berjangka yang selanjutnya
        disebut Lembaga Kliring Berjangka adalah badan usaha yang
        menyelenggarakan dan menyediakan sistem dan/atau sarana untuk
        pelaksanaan kliring dan penjaminan penyelesaian transaksi Perdagangan
        Berjangka.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Anggota Lembaga Kliring dan Penjaminan Berjangka yang selanjutnya
        disebut Anggota Kliring Berjangka adalah Anggota Bursa Berjangka yang
        mendapat hak untuk menggunakan sistem dan/atau sarana Lembaga Kliring
        Berjangka dan mendapat hak dari Lembaga Kliring Berjangka untuk
        melakukan kliring dan mendapatkan penjaminan dalam rangka penyelesaian
        transaksi Kontrak Berjangka, Kontrak Derivatif Syariah, dan/atau Kontrak
        Derivatif lainnya.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Pasar Fisik Aset Kripto (Crypto Asset) di Bursa Berjangka, yang
        selanjutnya disebut Pasar Fisik Aset Kripto adalah pasar fisik Aset
        Kripto yang diselenggarakan menggunakan sarana elektronik yang dimiliki
        oleh Pedagang Fisik Aset Kripto untuk transaksi jual atau beli Aset
        Kripto yang pengawasan pasarnya dilakukan oleh Bursa Berjangka.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Aset Kripto (Crypto Asset) yang selanjutnya disebut Aset Kripto adalah
        Komoditi tidak berwujud yang berbentuk digital, menggunakan kriptografi,
        jaringan informasi teknologi, dan buku besar yang terdistribusi, untuk
        mengatur penciptaan unit baru, memverifikasi transaksi, dan mengamankan
        transaksi tanpa campur tangan pihak lain.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Pedagang Fisik Aset Kripto adalah pihak yang telah memperoleh
        persetujuan dari Kepala Bappebti untuk melakukan kegiatan transaksi yang
        berkaitan dengan Aset Kripto baik atas nama diri sendiri dan/atau
        memfasilitasi Pelanggan Aset Kripto.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Pelanggan Aset Kripto adalah pihak yang menggunakan jasa Pedagang Fisik
        Aset Kripto untuk membeli atau menjual Aset Kripto yang diperdagangkan
        di Pasar Fisik Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Pengelola Tempat Penyimpanan Aset Kripto adalah pihak yang telah
        memperoleh persetujuan dari Kepala Bappebti untuk mengelola tempat
        penyimpanan Aset Kripto dalam rangka melakukan penyimpanan,
        pemeliharaan, pengawasan dan/atau penyerahan Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Bukti Simpan dan Serah Aset Kripto adalah dokumen baik dalam bentuk
        hardcopy atau softcopy yang diterbitkan oleh Pengelola Tempat
        Penyimpanan sebagai tanda bukti kepemilikan atau atas penyerahan atas
        Aset Kripto yang disimpan.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Wallet adalah media yang dipergunakan untuk menyimpan Aset Kripto baik
        berupa koin atau token.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Koin adalah salah satu bentuk Aset Kripto yang memiliki konfigurasi
        blockchain tersendiri dan memiliki karakteristik seperti Aset Kripto
        yang muncul pertama kali yaitu bitcoin.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Token adalah salah satu bentuk Aset Kripto yang dibuat sebagai produk
        turunan dari Koin.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        <span
          style="--darkreader-inline-bgcolor: #999900"
          data-darkreader-inline-bgcolor=""
          >Website/Trading Platform mengacu pada situs online atau aplikasi
          trading platform yang dikelola oleh PT BURSA KRIPTO INDONESIA, dengan
          tidak terbatas pada para pemilik, member, karyawan dan pihak-pihak
          yang terkait dengan PT BURSA KRIPTO INDONESIA. Tergantung pada
          konteks, website</span
        ><span
          style="--darkreader-inline-bgcolor: #999900"
          data-darkreader-inline-bgcolor=""
          >&nbsp;&nbsp;</span
        ><span
          style="--darkreader-inline-bgcolor: #999900"
          data-darkreader-inline-bgcolor=""
          >dapat juga mengacu pada jasa, produk, situs, konten atau layanan lain
          yang disediakan oleh PT BURSA KRIPTO INDONESIA.</span
        >
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Aset Kripto adalah komoditas kripto yang menggunakan prinsip teknologi
        desentralisasi berbasiskan jaringan peer-to-peer (antar muka) atau
        disebut dengan Jaringan Blockchain yang diperdagangkan di dalam
        platform.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Blockchain adalah sebuah buku besar terdistribusi (distributed ledger)
        terbuka yang dapat mencatat transaksi antara dua pihak secara efisien
        dan dengan cara yang dapat diverifikasi secara permanen.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Registrasi adalah proses pendaftaran menjadi Member dalam platform PT
        BURSA KRIPTO INDONESIA yang merupakan proses verifikasi awal untuk
        memperoleh keterangan, pernyataan dalam penggunaan layanan platform.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Member adalah orang (perseorangan) yang telah melakukan registrasi pada
        PT BURSA KRIPTO INDONESIA, sehingga memperoleh otorisasi dari platform
        PT BURSA KRIPTO INDONESIA untuk melakukan kegiatan perdagangan Aset
        Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Verifikasi adalah proses pemeriksaan terhadap Member mengenai keterangan
        terkait Member dan informasi pribadi yang dicantumkan dalam proses
        Registrasi untuk divalidasi oleh PT BURSA KRIPTO INDONESIA guna mendapat
        layanan penuh platform.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Verified Member adalah Member yang telah melalui dan menyelesaikan
        tahapan Verifikasi yang dilakukan oleh PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Layanan adalah jasa yang disediakan oleh PT BURSA KRIPTO INDONESIA dalam
        memfasilitasi Verified Member untuk melakukan kegiatan membeli dan
        menjual Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Kegiatan Perdagangan Aset Kripto adalah kegiatan transaksi jual-beli
        Aset Kripto atas dasar adanya pencapaian titik nilai kesepakatan para
        Verified Member dalam platform yang disediakan oleh PT BURSA KRIPTO
        INDONESIA.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Akun Member adalah akses yang diberikan kepada Member/Verified Member
        untuk mendapatkan informasi dan untuk melakukan kegiatan perdagangan
        Aset Kripto melalui platform.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Kata Sandi adalah kumpulan karakter yang terdiri dari rangkaian
        alfa-numerik atau kombinasi keduanya dan digunakan oleh Member/Verified
        Member untuk memverifikasi identitas dirinya kepada sistem keamanan pada
        platform PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Nomor Telepon adalah nomor telepon Member/Verified Member yang terdaftar
        sehingga memperoleh otorisasi untuk mendapatkan layanan. Perubahan nomor
        telepon dapat dilakukan dengan mengikuti peraturan pada laman bantuan
        yang tersedia di Website.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Konversi adalah perubahan nilai mata uang fiat atau kartal ke dalam
        bentuk atau format Aset Kripto berdasarkan nilai tukar/rate yang berlaku
        dan sebaliknya.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Pembeli adalah Verified Member yang melakukan pembelian Aset Kripto
        melalui platform. Dalam mekanisme transaksinya pembeli dapat melakukan
        pembelian Aset Kripto yang didasarkan dengan nilai tukar/rate mata uang
        Rupiah.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Penjual adalah Verified Member yang melakukan penjualan Aset Kripto
        melalui platform. Dalam mekanisme transaksinya penjual dapat melakukan
        penjualan Aset Kripto yang didasarkan dengan nilai tukar/rate mata uang
        Rupiah.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Deposit adalah proses penyimpanan Rupiah/Aset Kripto oleh
        Member/Verified Member yang merujuk pada proses atau mekanisme
        penambahan (top up) yang dilakukan melalui Akun Member/Verified Member.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Withdraw adalah proses penarikan Rupiah/Aset Kripto oleh Verified Member
        yang merujuk pada proses atau mekanisme penarikan (withdrawal) yang
        dilakukan melalui Akun Verified Member.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Harga Aset Kripto adalah nilai tukar/rate Aset Kripto dalam platform PT
        BURSA KRIPTO INDONESIA yang bersifat fluktuatif, dengan nilai sesuai
        permintaan (supply) dan persediaan (demand) pada market.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Alamat Aset Kripto adalah alamat dompet Aset Kripto milik
        Member/Verified Member, diciptakan khusus untuk Member/Verified Member
        dan dapat digunakan berkali-kali sebagai dompet Aset Kripto yang
        disediakan di dalam platform. Dalam faktor teknisnya, alamat Aset Kripto
        memiliki peranan ataupun fungsi untuk menerima dan mengirim Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Limit Pengiriman Aset Kripto adalah batas minimal dan maksimal dalam
        melakukan pengiriman Aset Kripto ke dompet Aset Kripto lain per-harinya.
        Perlu untuk dijelaskan bahwa setiap Akun Verified Member akan memiliki
        limit pengiriman yang berbeda dan disesuaikan dengan Syarat dan
        Ketentuan ini dengan Peraturan Perundang-Undangan yang berlaku.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Limit Penarikan Rupiah adalah batas minimal dan maksimal penarikan
        Rupiah per-harinya. Berkenaan dengan limit penarikan Rupiah. Perlu untuk
        dijelaskan bahwa setiap Akun Verified Member akan memiliki limit
        penarikan yang berbeda yang disesuaikan dengan Syarat dan Ketentuan dan
        Peraturan Perundang-Undangan yang berlaku.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Rekening Bank adalah rekening bank yang telah didaftarkan oleh Member
        dengan kewajiban kesamaan nama pemegang rekening dan nama Member.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Order Book adalah daftar antrian harga jual dan antrian harga beli yang
        tersedia pada website atau aplikasi trading platform agar Verified
        Member dapat membeli atau menjual Aset Kripto menggunakan harga yang
        ditentukan oleh Verified Member. Order Book dibagi menjadi dua bagian,
        yaitu:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Market Beli adalah Daftar permintaan pembelian Aset Kripto
              lengkap dengan jumlah Aset Kripto dan harga yang ditawarkan.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Market Jual adalah Daftar Aset Kripto yang dijual lengkap dengan
              jumlah Aset Kripto dan harga yang diminta.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Dompet Aset Kripto adalah komponen perangkat lunak dan informasi untuk
        menyimpan dan menggunakan Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Rupiah adalah mata uang Negara Kesatuan Republik Indonesia. Mata uang
        yang diperdagangkan terhadap Aset Kripto pada platform.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        KYC (Know Your Customer) Principles adalah proses penilaian terhadap
        calon Member dan Member untuk mengetahui latar belakang dan itikad baik
        terhadap perbuatan yang akan dilakukan dalam sebuah kegiatan perdagangan
        Aset Kripto.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        AML (Anti Money Laundering) adalah kegiatan untuk mengantisipasi dan
        menghentikan praktik pencucian uang.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Undang-Undang Nomor 27 Tahun 2022 tentang Perlindungan Data Pribadi
        selanjutnya disebut UU PDP adalah bentuk instrumen hukum berkenaan untuk
        memberikan perlindungan terhadap kerahasiaan data personal atau
        perseorangan.
      </li>
      <li style="margin-left: 36pt; margin-bottom: 14pt; text-align: justify">
        Sistem Keamanan 2 (dua) Langkah (Two Step Verification) adalah layanan
        yang diberikan platform PT BURSA KRIPTO INDONESIA sebagai opsi bagi
        Verified Member dalam Memberikan keamanan tambahan atas Akun Verified
        Member.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      PROSES REGISTRASI/PENDAFTARAN MEMBER INFORMASI DAN DATA MEMBER
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Syarat menjadi Member adalah:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Member dapat melakukan proses registrasi atau pendaftaran Member
              melalui platform PT BURSA KRIPTO INDONESIA;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Setuju dan sepakat untuk tunduk pada Syarat dan Ketentuan Umum
              ini;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Berusia minimal 18 (delapan belas) tahun, atau telah menikah; dan
              Memiliki identitas yang sah secara hukum.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Bukan merupakan warga Negara yang berasal dari negara Amerika
              Serikat, Myanmar, Cote D`Ivoire, Cuba, Iran, Republik Arab Syria,
              Belarus, Congo, Democratic Republic of Congo, Iraq, Liberia,
              Sudan, Zimbabwe, and Korea Utara.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Atas setiap proses registrasi, calon Member wajib menunjukkan semua dan
        setiap data sesuai dengan identitas diri berupa:
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Registrasi untuk Warga Negara Indonesia (WNI):
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Nama (sesuai dengan Identitas diri yang dilampirkan);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Alamat rumah sesuai identitas;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Alamat tinggal saat ini;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Nomor telepon atau Handphone (nomor harus aktif dan digunakan
              secara pribadi);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Tempat dan tanggal lahir (sesuai dengan identitas diri yang
              dilampirkan);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Kewarganegaraan;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Jenis kelamin;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Foto kartu identitas yang masih berlaku. Kartu identitas yang
              dapat digunakan adalah: Kartu Tanda Penduduk (KTP) &amp;
              Paspor;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Pekerjaan;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >E-mail (alamat surat elektronik) yang aktif;</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Registrasi untuk Warga Negara Asing (WNA):
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Nama (sesuai dengan Identitas diri yang dilampirkan);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Alamat rumah sesuai identitas;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Alamat tinggal saat ini;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Nomor telepon atau Handphone (nomor harus aktif dan digunakan
              secara pribadi);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Tempat dan tanggal lahir (sesuai dengan identitas diri yang
              dilampirkan);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Kewarganegaraan;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Jenis kelamin;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Foto kartu identitas yang masih berlaku. Paspor, Kartu Ijin
              Tinggal Terbatas (KITAS), dan Kartu Ijin Tinggal Terbatas
              (KITAP);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Pekerjaan;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >E-mail (alamat surat elektronik) yang aktif;</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Dan/atau segala sesuatu yang diminta dan diperlukan berkenaan dengan
        syarat registrasi yang ditentukan, dan calon Member dengan ini
        menyatakan serta menjamin bahwa segala
        data/keterangan/dokumen/informasi/pernyataan apapun yang diberikan
        berkenaan dengan proses registrasi sebagai Member PT BURSA KRIPTO
        INDONESIA adalah lengkap, asli, benar dan sesuai dengan keadaan yang
        sebenarnya serta merupakan data/ keterangan/ dokumen/ informasi/
        pernyataan terkini yang tidak/belum dilakukan perubahan dan masih
        berlaku/tidak daluarsa serta tidak/belum ada perubahan atau kondisi
        lainnya yang disetujui berdasarkan kebijakan dalam halaman registrasi
        pada Website.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member dengan ini setuju bahwa proses menjadi Member PT BURSA KRIPTO
        INDONESIA hanya akan berlaku efektif setelah seluruh persyaratan PT
        BURSA KRIPTO INDONESIA dipenuhi oleh Member dan proses registrasi telah
        melalui proses verifikasi untuk disetujui PT BURSA KRIPTO INDONESIA.
        Segala risiko yang timbul sehubungan dengan
        penutupan/pemblokiran/pembekuan Akun yang diakibatkan oleh kesalahan
        dan/atau kelalaian Verified Member, akan menjadi tanggung jawab Verified
        Member dan PT BURSA KRIPTO INDONESIA tidak akan memberikan ganti rugi
        kepada Verified Member atau Pihak manapun dalam bentuk apapun atas
        segala tuntutan/klaim dan ganti rugi dari Verified Member atau Pihak
        manapun sehubungan dengan penutupan Akun Verified Member.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Seluruh data, keterangan, informasi, pernyataan, dokumen yang diperoleh
        PT BURSA KRIPTO INDONESIA berkenaan dengan Member/Verified Member, akan
        menjadi milik PT BURSA KRIPTO INDONESIA dan PT BURSA KRIPTO INDONESIA
        berhak untuk melakukan verifikasi, mencocokan, menilai, merahasiakan
        atau menggunakannya untuk kepentingan PT BURSA KRIPTO INDONESIA sesuai
        dengan ketentuan hukum yang berlaku tanpa adanya kewajiban PT BURSA
        KRIPTO INDONESIA untuk memberitahu atau meminta persetujuan, memberikan
        jaminan atau ganti rugi dan dengan alasan apapun kepada Member/Verified
        Member.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA akan mengatur, mengelola dan melakukan
        pengawasan menurut tata cara/prosedur yang ditetapkan PT BURSA KRIPTO
        INDONESIA atas segala data, keterangan, informasi, pernyataan, dokumen
        atau segala sesuatu yang berkenaan dengan Member/Verified Member maupun
        kegiatan usaha atau transaksi Member/Verified Member yang terkait dengan
        Akun Member/Verified Member.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member dengan ini memberikan persetujuan dan kuasa
        kepada PT BURSA KRIPTO INDONESIA untuk menggunakan semua data,
        keterangan dan informasi yang diperoleh PT BURSA KRIPTO INDONESIA
        mengenai Member/Verified Member termasuk namun tidak terbatas pada
        penggunaan sarana komunikasi pribadi Member/Verified Member untuk segala
        keperluan lainnya sepanjang dimungkinkan dan diperkenankan oleh
        Perundang - Undangan yang berlaku, termasuk yang bertujuan untuk
        pemasaran bagi PT BURSA KRIPTO INDONESIA ataupun bagi pihak lain yang
        bekerjasama dengan PT BURSA KRIPTO INDONESIA. Untuk penggunaan data yang
        memerlukan persetujuan pihak lain, dengan ini Member/Verified Member
        menyatakan bahwa telah memberikan persetujuan tertulis kepada Pihak
        Ketiga manapun untuk penggunaan data, keterangan dan informasi tersebut,
        dan oleh karena itu PT BURSA KRIPTO INDONESIA dengan ini tidak akan
        memberikan ganti rugi dan/atau pertanggungjawaban dalam bentuk apapun
        kepada Member/Verified Member dan pihak manapun atas segala risiko,
        tuntutan, gugatan dan/atau ganti rugi yang mungkin timbul dikemudian
        hari sehubungan dengan penggunaan data, keterangan dan informasi yang
        telah memperoleh persetujuan tertulis tersebut oleh PT BURSA KRIPTO
        INDONESIA.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      PERNYATAAN DAN JAMINAN
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Semua layanan dalam Website/Aplikasi Trading Platform tidak Memberikan
        jaminan ataupun garansi dalam hal keuntungan/profit dalam perdagangan
        Aset Kripto dan PT BURSA KRIPTO INDONESIA tidak menjamin bahwa
        Website/Aplikasi Trading Platform akan selalu dapat diakses setiap
        waktu.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin akan menggunakan layanan
        PT BURSA KRIPTO INDONESIA dengan baik dan penuh tanggung jawab serta
        tidak melakukan tindakan yang berlawanan dengan hukum, undang- undang
        serta peraturan yang berlaku di wilayah Republik Indonesia.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin tidak akan menggunakan
        layanan PT BURSA KRIPTO INDONESIA dalam penjualan barang dan/jasa yang
        berhubungan dengan:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Narkotika, bahan dan/atau zat kimia untuk penelitian.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Uang dan apa pun yang sejenis dengan uang, termasuk
              derivative.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Barang dan/atau jasa yang melanggar Hak Kekayaan
              Intelektual.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Amunisi, senjata api, bahan peledak, senjata tajam sesuai dengan
              Ketentuan Hukum yang berlaku.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Barang dan/atau jasa yang menunjukkan informasi pribadi dari
              Pihak Ketiga yang melanggar hukum.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Dukungan terhadap skema Ponzi dan program.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Barang dan/atau jasa yang berhubungan dengan pembelian lotre,
              lay-away.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Jasa yang terkait dengan perbankan yang berada di luar wilayah
              Republik Indonesia.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Pembayaran kartu.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Penyelesaian kredit dan/atau.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Dukungan terhadap organisasi terlarang atau dilarang oleh
              pemerintah.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin untuk tidak menggunakan
        layanan terhadap perbuatan yang terlibat dalam praktik perjudian
        dan/atau kegiatan lain yang mengenakan biaya masuk dan menjanjikan
        hadiah, termasuk namun tidak terbatas pada permainan kasino, perjudian
        dalam olahraga, usaha yang memfasilitasi perjudian dan cara undian.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin bahwa seluruh data,
        informasi dan dokumen yang diberikan Member/Verified Member kepada PT
        BURSA KRIPTO INDONESIA merupakan data, informasi dan dokumen yang
        sebenar-benarnya, sah, jujur, transparan, lengkap dan akurat.
        Member/Verified Member menyatakan bersedia dituntut secara pidana maupun
        apabila PT BURSA KRIPTO INDONESIA mengetahui atau memperoleh informasi
        dari pihak manapun bahwa data, informasi dan dokumen yang diberikan
        Member/Verified Member ternyata tidak benar/tidak sepenuhnya
        benar/palsu. Member bersedia untuk melakukan pembaharuan data/informasi
        (profile update) apabila sewaktu-waktu diminta oleh PT BURSA KRIPTO
        INDONESIA dan selanjutnya seluruh dokumen yang sudah diberikan menjadi
        sepenuhnya milik PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA telah Memberikan keterangan dan penjelasan
        yang cukup mengenai layanan PT BURSA KRIPTO INDONESIA yang akan
        dipergunakan Member/Verified Member sesuai dengan ketentuan pada PTT dan
        Member/Verified Member telah mengerti dan memahami serta bersedia
        menanggung segala konsekuensi yang mungkin timbul sehubungan dengan
        penggunaan layanan PT BURSA KRIPTO INDONESIA termasuk manfaat, resiko
        dan biaya biaya yang melekat layanan dan layanan.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dengan ini menyetujui dan memberi kuasa kepada PT
        BURSA KRIPTO INDONESIA untuk menggunakan semua data, keterangan dan
        informasi yang diperoleh PT BURSA KRIPTO INDONESIA mengenai
        Member/Verified Member termasuk namun tidak terbatas pada penggunaan
        sarana komunikasi pribadi Member/Verified Member untuk segala keperluan
        lainnya sepanjang dimungkinkan dan diperkenankan oleh perundang-undangan
        yang berlaku, termasuk yang bertujuan untuk pemasaran produk-produk PT
        BURSA KRIPTO INDONESIA ataupun pihak lain, yang bekerjasama dengan PT
        BURSA KRIPTO INDONESIA. Untuk penggunaan data yang memerlukan
        persetujuan pihak lain, dengan ini Member/Verified Member menyatakan
        bahwa telah memperoleh persetujuan tertulis dari pihak ketiga manapun
        untuk penggunaan data, keterangan dan informasi tersebut, dan karena itu
        Member/Verified Member menjamin dan menyetujui bahwa PT BURSA KRIPTO
        INDONESIA tidak akan Memberikan ganti rugi dan/atau pertanggungjawaban
        dalam bentuk apapun kepada Member/Verified Member atau pihak manapun
        atas segala risiko, kerugian, tuntutan dan/atau tanggung jawab yang
        mungkin timbul dikemudian hari sehubungan dengan penggunaan data,
        keterangan dan informasi yang telah memperoleh persetujuan tertulis
        tersebut oleh PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin bahwa transaksi yang
        dilakukan dengan menggunakan layanan PT BURSA KRIPTO INDONESIA adalah
        transaksi yang tidak melanggar ketentuan peraturan perundang- undangan
        yang berlaku dan ketentuan mengenai penerimaan pelaksanaan transaksi
        sebagaimana diatur dalam PTT. Dalam hal PT BURSA KRIPTO INDONESIA
        menemukan indikasi pelaksanaan transaksi yang tidak sesuai dengan
        ketentuan peraturan perundang-undangan yang berlaku dan atau ketentuan
        mengenai penerimaan pelaksanaan transaksi yang diatur dalam PTT, maka PT
        BURSA KRIPTO INDONESIA mempunyai hak penuh untuk menutup Akun
        Member/Verified Member dengan menonaktifkan layanan PT BURSA KRIPTO
        INDONESIA pada Member/Verified Member dan Member/Verified Member dengan
        ini setuju bahwa PT BURSA KRIPTO INDONESIA dengan ini tidak akan
        Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun
        kepada Member/Verified Member, atau pihak manapun atas segala klaim dan
        atau tuntutan yang timbul sehubungan dengan penonaktifan layanan PT
        BURSA KRIPTO INDONESIA pada Member/Verified Member. Dana atas akun
        Member/Verified Member yang dinonaktifkan sebagaimana dimaksud pada poin
        8 diatas akan disimpan sementara oleh PT Bursa Kripto Indonesia untuk
        selanjutnya dilaporkan kepada Bappebti, setelah mendapatkan klarifikasi
        dari Bappebti PT Bursa Kripto Indonesia akan melakukan hal sebagai
        berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila ditemukan indikasi transaksi yang melanggar ketentuan
              peraturan perundang-undangan, PT Bursa Kripto Indonesia akan
              mengikuti prosedur yang ditentukan oleh Bappebti atas Dana yang
              dimiliki oleh Member/Verified Member yang melanggar serta
              memberitahukan hal tersebut kepada Member/Verified Member melalui
              nomor handphone/email yang didaftarkan.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila tidak ditemukan indikasi transaksi yang melanggar
              ketentuan peraturan perundang-undangan, PT Bursa Kripto Indonesia
              akan mengembalikan kepada Member/Verified Member.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member menyatakan dan menjamin bahwa resiko terhadap
        penggunaan layanan Website/Aplikasi Trading Platform, produk dan promosi
        Pihak Ketiga dengan Member/Verified Member (apabila ada) yaitu:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Bank atau Penyedia Virtual Account (Bank CIMB dan Bank
              BCA).</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              margin-bottom: 14pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Penyedia system KYT (Chainalysis).</span
            >
          </li>
        </ul>
      </li>
    </ol>
    <p
      style="
        margin-top: 14pt;
        margin-left: 36pt;
        margin-bottom: 14pt;
        text-align: justify;
      "
    >
      Member/Verified Member menyatakan bahwa PT BURSA KRIPTO INDONESIA tidak
      bertanggung jawab atas layanan dan kinerja layanan Pihak Ketiga.
    </p>
    <ol start="10" type="1" style="margin: 0pt; padding-left: 0pt">
      <li style="margin-top: 14pt; margin-left: 36pt; text-align: justify">
        Member/Verified Member dengan ini bertanggung jawab sepenuhnya dan
        setuju bahwa PT BURSA KRIPTO INDONESIA tidak akan Memberikan ganti rugi
        dan atau pertanggungjawaban dalam bentuk apa pun kepada Member/Verified
        Member atau pihak manapun atas segala kerugian dan atau klaim dan atau
        tuntutan yang timbul atau mungkin dialami oleh PT BURSA KRIPTO INDONESIA
        sebagai akibat dari kelalaian atau kegagalan Member/Verified Member
        dalam menjalankan transaksi.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Member/Verified Member dengan ini Memberikan jaminan kepada PT BURSA
        KRIPTO INDONESIA bahwa Member/Verified Member beserta dengan seluruh
        karyawannya dan atau pihak lain yang bekerja sama dengan Member/Verified
        Member tidak akan memperbanyak dan atau membuat, Memberikan, menyewakan,
        menjual, memindahkan, mengalihkan, dan atau mengalih-fungsikan layanan
        PT BURSA KRIPTO INDONESIA baik sebagian atau seluruhnya kepada pihak
        lain dengan alasan apapun, termasuk namun tidak terbatas pada
        penyalahgunaan layanan Website/Trading Platform PT BURSA KRIPTO
        INDONESIA untuk melakukan transaksi selain dari yang telah ditentukan
        dalam PTT dengan maksud apa pun, termasuk namun tidak terbatas untuk
        kejahatan/penipuan/kecurangan. Apabila Member/Verified Member melanggar
        ketentuan tersebut, maka Member/Verified Member wajib bertanggung jawab
        atas segala kerugian, tuntutan dan atau gugatan yang timbul akibat dari
        pelanggaran tersebut dan dengan ini setuju bahwa PT BURSA KRIPTO
        INDONESIA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban
        dalam bentuk apapun kepada Member/Verified Member atau pihak manapun
        atas segala klaim dan atau tuntutan dan atau gugatan yang timbul akibat
        pelanggaran tersebut.
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Sebagai wujud komitmen dan bentuk kepatuhan kami terhadap UU PDP, maka
        bersama dengan Syarat dan Ketentuan Umum (PTT) ini PT BURSA KRIPTO
        INDONESIA menjamin bahwa:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA terus selalu berkomitmen dan menjamin
              data personal Member/Verified Member kami dan akan menindak tegas
              apabila ada pihak lain telah menggunakan data informasi
              Member/Verified Member kami;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA menjamin transfer lintas batas Aset
              Kripto;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA selalu menerapkan protokoler dan
              mekanisme berupa permintaan ijin dan persetujuan atau notifikasi
              kepada Member/Verified Member untuk segala bentuk pemrosesan data
              di marketplace didalam melakukan deposit, penarikan dan/atau
              pembaharuan data.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 36pt; text-align: justify">
        Penolakan jaminan dan batasan tanggung jawab : PT BURSA KRIPTO INDONESIA
        selalu berusaha untuk menjaga Layanan yang aman, nyaman, dan berfungsi
        dengan baik, tetapi kami tidak dapat menjamin pengoperasian yang
        berkelanjutan atau bahwa akses ke Layanan kami selalu sempurna. Ada
        kemungkinan informasi dan data di situs PT BURSA KRIPTO INDONESIA tidak
        tersedia secara real time.
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA tidak menjamin bahwa hasil yang
              diperoleh dari penggunaan layanan akan akurat atau dapat
              diandalkan.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Member / Verified Member setuju bahwa Member / Verified Member
              menggunakan Layanan PT BURSA KRIPTO INDONESIA atas risiko Member /
              Verified Member sendiri, dan bahwa Layanan PT BURSA KRIPTO
              INDONESIA disediakan untuk Member / Verified Member atas dasar
              SEBAGAIMANA ADANYA dan SEBAGAIMANA TERSEDIA.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Sejauh diizinkan oleh hukum yang berlaku, PT BURSA KRIPTO
              INDONESIA (termasuk Perusahaan Induk, direktur, dan karyawan)
              tidak bertanggung jawab, dan pelanggan setuju untuk tidak meminta
              pertanggungjawaban PT BURSA KRIPTO INDONESIA, atas kerusakan atau
              kerugian (termasuk tetapi tidak terbatas pada kehilangan uang,
              reputasi, keuntungan, atau kerugian tidak berwujud lainnya) yang
              secara langsung atau tidak langsung disebabkan oleh hal-hal
              berikut:&nbsp;</span
            >
            <ul
              type="square"
              style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
            >
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Ketidakmampuan Member / Verified Member dalam menggunakan
                  layanan PT BURSA KRIPTO INDONESIA termasuk dalam risiko
                  pribadi.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Hilangnya Penggunaan, Hilangnya Keuntungan, Hilangnya
                  Pendapatan, Hilangnya Data, Hilangnya Niat Baik, atau
                  Kegagalan untuk merealisasikan simpakan yang diharapkan, untuk
                  kasus apa pun secara langsung atau tidak langsung.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Setiap kerugian tidak langsung, insidental, khusus, atau
                  konsekuensial, yang timbul dari atau sehubungan dengan
                  penggunaan atau ketidakmampuan untuk menggunakan situs web
                  atau layanan PT BURSA KRIPTO INDONESIA termasuk, tetapi tidak
                  terbatas pada, kerugian yang disebabkan olehnya, bahkan jika
                  PT BURSA KRIPTO INDONESIA telah diberitahu tentang kemungkinan
                  tersebut kerugian.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Segala kerugian yang disebabkan oleh kelalaian Member /
                  Verified Member termasuk namun tidak terbatas pada kelalaian
                  login melalui perangkat pihak ketiga dan / atau kegagalan
                  untuk menjaga kerahasiaan perangkat yang digunakan untuk
                  login.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Kondisi dan kualitas produk atau aset crypto yang dapat
                  diperdagangkan.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Pelanggaran Hak Kekayaan Intelektual.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Perselisihan antara Anggota / Anggota Terverifikasi.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Pencemaran nama baik pihak lain.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Setiap penyalahgunaan Aset yang dibeli oleh Member / Verified
                  Member.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Kerugian akibat pembayaran tidak resmi kepada pihak lain
                  selain Rekening Resmi PT BURSA KRIPTO INDONESIA yang dengan
                  cara apapun menggunakan nama PT BURSA KRIPTO INDONESIA atau
                  kelalaian dalam penulisan rekening dan / atau informasi lain
                  dan / atau kelalaian pihak bank dalam hal pembayaran tidak
                  masuk ke rekening resmi PT BURSA KRIPTO INDONESIA.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Kesesuaian untuk tujuan tertentu, daya tahan, kepemilikan,
                  dan non-pelanggaran.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Virus atau malware lainnya (bot, script, alat otomatisasi)
                  yang diperoleh dengan mengakses atau menghubungkan ke layanan
                  PT BURSA KRIPTO INDONESIA.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Proses skimming atau hacking, yang menyebabkan kerugian
                  Member / Verified Member di Layanan PT BURSA KRIPTO
                  INDONESIA.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Gangguan, bug, kesalahan, atau ketidakakuratan dalam Layanan
                  PT BURSA KRIPTO INDONESIA.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Kerusakan perangkat keras Anda dari penggunaan Layanan PT
                  BURSA KRIPTO INDONESIA apa pun.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Tindakan penegakan yang diambil sehubungan dengan akun Member
                  / Verified Member.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Setiap peretasan yang dilakukan oleh pihak ketiga pada akun
                  Member / Verified Member.</span
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li style="margin-left: 36pt; margin-bottom: 14pt; text-align: justify">
        Member / Verified Member mengakui dan menyetujui bahwa satu-satunya hak
        Anggota / Verified Member sehubungan dengan masalah atau ketidakpuasan
        layanan adalah berhenti menggunakan layanan.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      KEWAJIBAN DAN TANGGUNG JAWAB
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member setuju untuk menanggung setiap risiko, kerugian
        atau akibat yang diderita Member/Verified Member yang disebabkan oleh,
        antara lain:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >kerusakan, keterlambatan, kehilangan atau kesalahan pengiriman
              perintah dan komunikasi, secara elektronik yang disebabkan oleh
              Member/Verified Member;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Laporan Akun PT BURSA KRIPTO INDONESIA atau pemberitahuan
              penggunaan layanan PT BURSA KRIPTO INDONESIA yang dikirim kepada
              Member/Verified Member diterima atau dibaca atau disalahgunakan
              oleh pihak yang tidak berwenang atas Akun PT BURSA KRIPTO
              INDONESIA;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Password Akun diketahui oleh orang/pihak lain atas kesalahan
              Member/Verified Member.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member memahami dan setuju bahwa Member/Verified Member
        akan menggunakan Akun dan layanan PT BURSA KRIPTO INDONESIA untuk
        transaksi yang tidak bertentangan dengan ketentuan peraturan
        perundang-undangan dan/atau kebijakan internal PT BURSA KRIPTO INDONESIA
        yang berlaku dan/atau peraturan-peraturan lainnya yang berlaku secara
        nasional maupun internasional yang terkait dengan pelaksanaan transaksi
        tersebut baik secara langsung maupun tidak langsung, dan PT BURSA KRIPTO
        INDONESIA tidak akan Memberikan ganti rugi dan/atau pertanggungjawaban
        dalam bentuk apa pun kepada Member/Verified Member atau pihak mana pun
        atas segala klaim dan/atau tuntutan dan/atau kerugian yang timbul
        sehubungan dengan penggunaan layanan PT BURSA KRIPTO INDONESIA oleh
        Member/Verified Member untuk transaksi yang dikategorikan sebagai
        transaksi yang mencurigakan dan/atau transaksi yang dilarang oleh
        ketentuan peraturan perundang-undangan dan/atau kebijakan internal PT
        BURSA KRIPTO INDONESIA yang berlaku dan/atau peraturan-peraturan lainnya
        yang berlaku baik secara nasional maupun secara internasional yang
        terkait dengan kegiatan transaksi yang dilakukan oleh Member/Verified
        Member secara langsung maupun tidak langsung.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam melakukan transaksi yang bertentangan dengan ketentuan peraturan
        perundang-undangan menggunakan layanan PT BURSA KRIPTO INDONESIA,
        pelanggan dapat dikenakan sanksi-sanksi tertentu oleh pemerintah,
        termasuk pemerintah Negara lain, dan/atau instansi berwenang lainnya
        terhadap beberapa negara, badan dan perorangan. Mengacu pada hal
        tersebut, PT BURSA KRIPTO INDONESIA berhak untuk tidak
        melaksanakan/memproses transaksi yang merupakan pelanggaran terhadap
        ketentuan sanksi tersebut, dan instansi berwenang dapat mensyaratkan
        pengungkapan informasi terkait. PT BURSA KRIPTO INDONESIA tidak
        bertanggung jawab apabila Website/Trading Platform PT BURSA KRIPTO
        INDONESIA atau pihak lain gagal atau menunda pelaksanaan transaksi, atau
        pengungkapan informasi sebagai akibat pelanggaran langsung maupun tidak
        langsung atas ketentuan sanksi tersebut.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Risiko
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Perdagangan Aset Kripto merupakan aktivitas berisiko tinggi. Harga Aset
        Kripto fluktuatif, di mana harga dapat berubah secara signifikan dari
        waktu ke waktu. Sehubungan dengan fluktuasi harga, nilai Aset Kripto
        dapat bertambah maupun berkurang secara signifikan sewaktu-waktu. Semua
        Aset Kripto atau tidak, berpotensi untuk mengalami perubahan nilai
        secara drastis atau bahkan menjadi tidak berarti. Terdapat risiko
        kehilangan yang tinggi sebagai dampak dari membeli, menjual, atau
        berdagang apapun di pasar dan PT BURSA KRIPTO INDONESIA tidak
        bertanggung jawab atas perubahan fluktuasi dari nilai tukar Aset Kripto.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Perdagangan Aset Kripto juga memiliki risiko tambahan yang tidak dialami
        oleh Aset Kripto atau komoditas lain di pasar. Tidak seperti mata uang
        kebanyakan yang dijamin oleh pemerintah atau lembaga legal lainnya, atau
        oleh emas dan perak, crypto asset merupakan sebuah Aset Kripto yang unik
        dan dijamin oleh teknologi dan rasa percaya. Tidak ada bank sentral yang
        dapat mengontrol, melindungi nilai Aset Kripto dalam krisis, atau
        mencetak mata uang tersebut.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dihimbau untuk berhati-hati dalam mengukur
        situasi finansial dan memastikan bahwa Member/Verified Member bersedia
        menghadapi risiko yang ada dalam menjual, membeli, atau berdagang Aset
        Kripto. Member/Verified Member disarankan dengan sangat untuk melakukan
        riset pribadi sebelum membuat keputusan untuk memperjualbelikan Aset
        Kripto. Semua keputusan perdagangan Aset Kripto merupakan keputusan
        independen oleh pengguna secara sadar tanpa paksaan dan melepaskan PT
        BURSA KRIPTO INDONESIA atas kegiatan perdagangan Aset Kripto.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA tidak menjamin kelangsungan jangka panjang
        dari Aset Kripto yang diperdagangkan maupun ditukar di dalam
        marketplace.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Berkenaan dengan kegiatan penambangan Aset Kripto perlu untuk diluruskan
        dan ditegaskan bahwa PT BURSA KRIPTO INDONESIA tidak melakukan kegiatan
        menambang, memproduksi atau mencetak Aset Kripto. Aset Kripto diciptakan
        dan ditambang menggunakan software khusus oleh para penambang (miner)
        yang tersebar secara acak di seluruh dunia dan saling terhubung dengan
        teknologi peer-to-peer di jaringan blockchain.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Larangan
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Tanpa mengurangi maksud dari ketentuan mengenai larangan-larangan yang
        terdapat dalam PTT, maka Member/Verified Member dilarang untuk melakukan
        hal-hal sebagai berikut:
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member tidak diperbolehkan melakukan tindakan-tindakan
        yang dapat mengakibatkan kerugian bagi PT BURSA KRIPTO INDONESIA dan
        atau yang bertentangan dengan PTT dan peraturan perundang-undangan yang
        berlaku.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Apabila Member/Verified Member melanggar salah satu atau beberapa
        ketentuan dalam ketentuan ini, maka Member/Verified Member akan
        bertanggung jawab sepenuhnya dan dengan ini setuju bahwa atas
        pelanggaran tersebut PT BURSA KRIPTO INDONESIA berhak untuk membatasi
        fitur penggunaan layanan PT BURSA KRIPTO INDONESIA dan Member/Verified
        Member mengetahui dan setuju bahwa PT BURSA KRIPTO INDONESIA tidak akan
        Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apapun
        kepada Member/Verified Member atau pihak mana pun atas segala resiko
        atau akibat yang timbul atas pembatasan penggunaan layanan tersebut.
        Member/Verified Member selanjutnya berkewajiban untuk membayar ganti
        rugi kepada PT BURSA KRIPTO INDONESIA sebesar nilai kerugian yang
        mungkin dialami PT BURSA KRIPTO INDONESIA atas pelanggaran tersebut.
        Member/Verified Member dengan ini Memberikan kuasa kepada PT BURSA
        KRIPTO INDONESIA untuk melakukan pendebetan Akun Member/Verified Member
        untuk pembayaran ganti rugi tersebut. Dalam hal dana pada Akun
        Member/Verified Member tidak tersedia dan atau tidak mencukupi, maka
        Member wajib mengembalikan seluruh dana tersebut secara tunai kepada PT
        BURSA KRIPTO INDONESIA dalam waktu selambat-lambatnya 7 (tujuh) hari
        kerja sejak PT BURSA KRIPTO INDONESIA mengajukan klaim dimaksud.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Kerahasiaan
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Selama bekerja sama dengan PT BURSA KRIPTO INDONESIA dan pada setiap
        waktu sesudahnya, maka:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Setiap informasi dan atau data yang diberikan oleh PT BURSA
              KRIPTO INDONESIA kepada Member/Verified Member dan atau data yang
              diperoleh Member/Verified Member sebagai pelaksanaan dari PTT baik
              yang diberikan atau disampaikan secara lisan, tertulis, grafik
              atau yang disampaikan melalui media elektronik atau informasi dan
              atau data dalam bentuk lainnya selama berlangsungnya pembicaraan
              atau selama pekerjaan lain adalah bersifat rahasia (selanjutnya
              disebut sebagai Informasi Rahasia).</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Member/Verified Member setuju dan sepakat bahwa setiap saat akan
              merahasiakan informasi rahasia yang diperoleh sebagai pelaksanaan
              dari kerja sama kepada siapa pun atau tidak akan menggunakannya
              untuk kepentingan Member/Verified Member atau kepentingan pihak
              lainnya, tanpa terlebih dahulu memperoleh persetujuan tertulis
              dari pejabat yang berwenang dari PT BURSA KRIPTO INDONESIA atau
              pihak yang berwenang lainnya sesuai dengan ketentuan hukum yang
              berlaku.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila Member/Verified Member melanggar ketentuan sebagaimana
              dimaksud dalam angka 1 dan 2 ketentuan mengenai kerahasiaan ini,
              maka segala kerugian, tuntutan dan atau gugatan yang dialami PT
              BURSA KRIPTO INDONESIA merupakan tanggung jawab Member/Verified
              Member sepenuhnya, dan atas permintaan pertama dari PT BURSA
              KRIPTO INDONESIA, Member/Verified Member berkewajiban untuk
              menyelesaikannya sesuai dengan ketentuan hukum dan
              perundang-undangan yang berlaku dan Memberikan ganti rugi yang
              mungkin timbul akibat pelanggaran tersebut kepada PT BURSA KRIPTO
              INDONESIA Member/Verified Member dengan ini setuju bahwa PT BURSA
              KRIPTO INDONESIA tidak akan Memberikan ganti rugi dan atau
              pertanggungjawaban dalam bentuk apa pun kepada Member/Verified
              Member atau pihak mana pun atas segala tuntutan dan atau gugatan
              yang mungkin timbul dikemudian hari sehubungan dengan pelanggaran
              tersebut.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Kewajiban untuk menyimpan informasi rahasia sebagaimana dimaksud
              dalam angka 1 dan 2 ketentuan mengenai kerahasiaan menjadi tidak
              berlaku, apabila:&nbsp;</span
            >
            <ul
              type="square"
              style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
            >
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Informasi rahasia tersebut menjadi tersedia untuk masyarakat
                  umum.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Informasi rahasia tersebut diperintahkan untuk dibuka untuk
                  memenuhi perintah pengadilan atau badan pemerintahan lain yang
                  berwenang.</span
                >
              </li>
              <li style="margin-left: 27.58pt; padding-left: 8.42pt">
                <span style="font-family: 'Times New Roman'; font-size: 12pt"
                  >Informasi rahasia tersebut diberikan sesuai ketentuan hukum
                  yang berlaku.</span
                >
              </li>
            </ul>
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Member/Verified Member selanjutnya menyetujui untuk melakukan
              segenap upaya dan mengambil setiap tindakan yang diperlukan untuk
              menghindari pihak-pihak ketiga dalam memperoleh akses terhadap
              atau mengakibatkan terjadinya pengungkapan atas informasi
              rahasia.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              margin-bottom: 14pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Dalam hal kerjasama telah berakhir, Member/Verified Member
              sepakat bahwa kewajiban untuk merahasiakan dokumen dan materi yang
              merupakan informasi rahasia sebagaimana diatur dalam ketentuan ini
              akan tetap berlaku.</span
            >
          </li>
        </ul>
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Kelalaian
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member sepakat bahwa yang dimaksud dengan Kelalaian
        (Wanprestasi) adalah hal atau peristiwa sebagai berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Kelalaian (Wanprestasi).</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila Member/Verified Member lalai dalam melaksanakan sesuatu
              kewajiban atau melanggar suatu ketentuan dalam PTT.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Pernyataan Tidak Benar.</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Bilamana ternyata bahwa suatu pernyataan atau jaminan yang
              diberikan oleh Member/Verified Member dalam PTT tidak benar atau
              tidak sesuai dengan kenyataannya.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Dalam hal terjadi suatu kejadian kelalaian berdasarkan angka 1 ketentuan
        kelalaian ini oleh Member/Verified Member, maka PT BURSA KRIPTO
        INDONESIA dapat memilih apakah tetap meneruskan atau menutup Akun
        Member/Verified Member. Apabila PT BURSA KRIPTO INDONESIA berkehendak
        untuk menutup Akun Member/Verified Member, maka kehendak tersebut harus
        diberitahukan kepada Member/Verified Member dalam waktu yang wajar
        menurut PT BURSA KRIPTO INDONESIA.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam hal terjadi kejadian kelalaian oleh Member/Verified Member
        sebagaimana dimaksud, maka Website/Trading Platform PT BURSA KRIPTO
        INDONESIA berhak dengan seketika melakukan penonaktifan Akun
        Member/Verified Member pada PT BURSA KRIPTO INDONESIA tanpa harus
        melakukan pemberitahuan terlebih dahulu kepada Member/Verified Member,
        dan Member/Verified Member dengan ini setuju bahwa PT BURSA KRIPTO
        INDONESIA tidak akan Memberikan ganti rugi dan atau pertanggungjawaban
        dalam bentuk apa pun kepada Member/Verified Member atau pihak mana pun
        atas segala tuntutan dan atau gugatan dan atau klaim dan atau permintaan
        ganti kerugian dari pihak mana pun yang mungkin timbul sehubungan dengan
        terjadinya kelalaian tersebut.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Hak Kekayaan Intelektual
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member menyatakan dan menyetujui PT BURSA KRIPTO
        INDONESIA sebagai pemegang hak kepemilikan atas layanan, perangkat
        lunak, alat teknologi dan konten, situs, dan bahan lain termasuk Hak
        Kekayaan Intelektual yang terkait dengan fasilitas PT BURSA KRIPTO
        INDONESIA.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member hanya diperbolehkan untuk melihat, mencetak
        dan/atau mengunduh kopi material dari Website untuk penggunaan pribadi
        dan non-komersial. Seluruh penggunaan komersial perlu mendapatkan ijin
        dari PT BURSA KRIPTO INDONESIA Setiap kegiatan komersial tanpa seizin PT
        BURSA KRIPTO INDONESIA diartikan sebagai pelanggaran atas Hak Kekayaan
        Intelektual PT BURSA KRIPTO INDONESIA dan mengakibatkan pemberhentian
        Akun PT BURSA KRIPTO INDONESIA pada Member/Verified Member.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Pemblokiran dan Pembekuan Akun Member / Verified Member
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam rangka menjalankan prinsip kehati-hatian, PT BURSA KRIPTO
        INDONESIA berhak dan Member/Verified Member dengan ini memberi kuasa
        kepada PT BURSA KRIPTO INDONESIA untuk memblokir baik sebagian atau
        seluruh saldo Member/Verified Member (hold amount) dan/atau mendebitnya
        serta melakukan pemberhentian Akun, apabila terjadi hal berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Adanya permintaan dari pihak perbankan dikarenakan adanya
              kesalahan pengiriman dana dan pihak perbankan tersebut meminta
              dilakukan pemblokiran;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Menurut pendapat dan pertimbangan PT BURSA KRIPTO INDONESIA
              terdapat kesalahan penerimaan transaksi;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA digunakan sebagai tempat penampungan
              yang diindikasikan hasil kejahatan termasuk namun tidak terbatas
              pada Tindak Pidana korupsi, penyuapan, narkotika, psikotropika,
              penyelundupan tenaga kerja, penyelundupan manusia, bidang
              perbankan, bidang pasar modal, bidang perasuransian, kepabeanan,
              cukai, perdagangan manusia, perdagangan senjata gelap, terorisme,
              penculikan, pencurian, penggelapan, penipuan, pemalsuan,
              perjudian, prostitusi, bidang perpajakan, dan terorisme;</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dinilai lalai dalam melakukan kewajiban
        berdasarkan PTT;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Kebijakan PT BURSA KRIPTO INDONESIA atau oleh suatu ketetapan pemerintah
        atau instansi yang berwenang atau peraturan yang berlaku, sehingga PT
        BURSA KRIPTO INDONESIA diharuskan melakukan pemblokiran dan atau
        pembekuan Akun PT BURSA KRIPTO INDONESIA tersebut.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Apabila terjadi pemberhentian Akun, Member/Verified Member setuju dan
        menyatakan bersedia untuk tetap terikat dengan PTT menghentikan
        penggunaan layanan PT BURSA KRIPTO INDONESIA, memberikan hak kepada
        Website/Trading Platform PT BURSA KRIPTO INDONESIA untuk menghapus semua
        informasi dan data dalam server PT BURSA KRIPTO INDONESIA, dan
        menyatakan PT BURSA KRIPTO INDONESIA tidak bertanggung jawab kepada
        Member/Verified Member atau Pihak Ketiga atas penghentian akses dan
        penghapusan informasi serta data dalam Akun Member/Verified Member.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Pemblokiran dan Pembekuan Akun Member/Verified Member
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam rangka menjalankan prinsip kehati-hatian, PT BURSA KRIPTO
        INDONESIA berhak dan Member/Verified Member dengan ini memberi kuasa
        kepada PT BURSA KRIPTO INDONESIA untuk memblokir baik sebagian atau
        seluruh saldo Member/Verified Member (hold amount) dan/atau mendebitnya
        serta melakukan pemberhentian Akun, apabila terjadi hal berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Adanya permintaan dari pihak perbankan dikarenakan adanya
              kesalahan pengiriman dana dan pihak perbankan tersebut meminta
              dilakukan pemblokiran;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Menurut pendapat dan pertimbangan PT BURSA KRIPTO INDONESIA
              terdapat kesalahan penerimaan transaksi;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA digunakan sebagai tempat penampungan
              yang diindikasikan hasil kejahatan termasuk namun tidak terbatas
              pada Tindak Pidana korupsi, penyuapan, narkotika, psikotropika,
              penyelundupan tenaga kerja, penyelundupan manusia, bidang
              perbankan, bidang pasar modal, bidang perasuransian, kepabeanan,
              cukai, perdagangan manusia, perdagangan senjata gelap, terorisme,
              penculikan, pencurian, penggelapan, penipuan, pemalsuan,
              perjudian, prostitusi, bidang perpajakan, dan terorisme;</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dinilai lalai dalam melakukan kewajiban
        berdasarkan PTT;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Kebijakan PT BURSA KRIPTO INDONESIA atau oleh suatu ketetapan pemerintah
        atau instansi yang berwenang atau peraturan yang berlaku, sehingga PT
        BURSA KRIPTO INDONESIA diharuskan melakukan pemblokiran dan atau
        pembekuan Akun PT BURSA KRIPTO INDONESIA tersebut.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Apabila terjadi pemberhentian Akun, Member/Verified Member setuju dan
        menyatakan bersedia untuk tetap terikat dengan PTT menghentikan
        penggunaan layanan PT BURSA KRIPTO INDONESIA, memberikan hak kepada
        Website/Trading Platform PT BURSA KRIPTO INDONESIA untuk menghapus semua
        informasi dan data dalam server PT BURSA KRIPTO INDONESIA, dan
        menyatakan PT BURSA KRIPTO INDONESIA tidak bertanggung jawab kepada
        Member/Verified Member atau Pihak Ketiga atas penghentian akses dan
        penghapusan informasi serta data dalam Akun Member/Verified Member.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      PENGKINIAN DATA PELANGGAN/MEMBER
    </h2>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Perubahan Nama Akun Member/Verified Member
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Perubahan Nama Akun Member/Verified Member dapat dilakukan dengan
        mekanisme yang diwajibkan untuk mengirimkan nama lama dan nama baru
        pemilik Akun, username, e-mail, nomor telepon, dan nomor kartu identitas
        yang digunakan (KTP/Passport/KITAS/KITAP). PT BURSA KRIPTO INDONESIA
        akan melakukan proses pencocokan data dan akan melakukan konfirmasi ke
        nomor telepon yang terdaftar di Akun Member/Verified Member untuk
        menghindari terjadinya penipuan. Jika proses perubahan telah terlewati
        maka nama Akun akan berubah sesuai keinginan Member/Verified Member.
        Berkenaan dengan perubahan nama Akun ini, Contoh alasan yang
        diperbolehkan adalah: ejaan nama salah, calon Member/Verified Member
        diwajibkan untuk mengirimkan softcopy Kartu Tanda Pengenal (KTP) ke
        email support yang tertera dalam Website dengan subjek Data Kelengkapan
        Penggantian Nama Akun.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Perubahan Data Pendukung
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Perubahan dan Pembaharuan Data Pendukung wajib dilakukan oleh
        Member/Verified Member terhadap data berupa nomor telepon, alamat e-
        mail dan data pendukung lainnya sesuai dengan kondisi terkini. Hal ini
        sesuai dengan prinsip KYC (Know Your Customer) Principles yang
        diterapkan oleh PT BURSA KRIPTO INDONESIA. Perubahan dan Pembaharuan
        Data dilakukan dengan melakukan pengiriman e-mail dengan subjek
        Perubahan Data ke email support yang tertera dalam Website dengan
        menuliskan data apa yang ingin disesuaikan oleh Member/Verified Member
        disertai alasan perubahan. Proses perubahan wajib disertai dengan
        pencantuman softcopy identitas diri, username, nama lengkap, alamat,
        e-mail, data lama yang ingin diubah dan data perubahannya. Untuk
        selanjutnya, PT BURSA KRIPTO INDONESIA akan memberikan notifikasi dengan
        menghubungi nomor telepon utama atau nomor telepon alternatif yang telah
        terdaftar.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Perubahan Password
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Perubahan Password dimungkinkan sebagai alasan keamanan dan mekanismenya
        telah tersedia dalam layanan. Demi keamanan setelah mengganti password,
        Akun Member/Verified Member akan terkunci selama 48 (empat puluh
        delapan) jam. Member/Verified Member tidak dapat melakukan penarikan
        dana pada saat Akun dikunci meskipun transaksi lain berjalan seperti
        biasa. Member/Verified Member dapat membuka kembali Akun Member/Verified
        Member dengan memasukkan kembali PIN Google Authenticator atau
        verifikasi PIN SMS pada menu Keamanan.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      TATA CARA KEGIATAN TRANSAKSI
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Website/Aplikasi Trading Platform memperkenankan Verified Member untuk
      melakukan kegiatan perdagangan pada Website mengenai:
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Pembelian Aset Kripto dengan mata uang Rupiah. Pembelian Aset Kripto
        dengan dana rupiah yang sudah dideposit kedalam Website/Aplikasi Trading
        oleh Pelanggan Aset Kripto
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Penjualan Aset Kripto dengan mata uang Rupiah. Penjualan Aset Kripto
        yang dimiliki oleh Pelanggan Aset Kripto
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Melakukan deposit uang dalam mata uang Rupiah. Deposit dana rupiah oleh
        Pelanggan Aset Kripto sebelum melakukan pembelian Aset Kripto
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Melakukan penarikan dalam mata uang Rupiah. Penarikan dana rupiah oleh
        Pelanggan Aset Kripto dan ditujukan kepada rekening Bank yang sudah
        terdaftar pada website/aplikasi trading platform PT BURSA KRIPTO
        INDONESIA
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Melakukan pengiriman Aset Kripto kepada Member/Verified Member lain di
        dalam dan di luar Website/Aplikasi Trading Platform. Pengiriman Aset
        Kripto kepada Pelanggan lain baik sesama Pelanggan ataupun bukan
        Pelanggan PT BURSA KRIPTO INDONESIA
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Menerima deposit dalam berbagai bentuk Aset Kripto. Verified Member
        dapat menerima Aset Kripto yang dikirim oleh Pelanggan baik sesama
        Pelanggan ataupun bukan Pelanggan PT BURSA KRIPTO INDONESIA
      </li>
    </ol>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Metode-metode dalam tata cara transaksi:
    </p>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Metode Order Book Merupakan metode dalam layanan dimana Verified Member
      dapat menentukan harga jual/ beli dalam melakukan transaksi perdagangan
      Aset Kripto. Berikut adalah mekanisme dari transaksi order book:
    </p>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Contoh Kasus Beli
    </p>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Kondisi awal
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Sudah ada order book jual dalam antrian, misalnya:</span
        >
      </li>
    </ul>
    <table
      cellspacing="2"
      cellpadding="0"
      style="
        border-spacing: 1.5pt;
        background-color: #999900;
        width: 100%;
        margin-bottom: 10px;
      "
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK JUAL KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 32.500,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 70.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 105.000,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify">
      <span style="display: none">&nbsp;</span>
    </p>
    <table
      cellspacing="2"
      cellpadding="4"
      style="border-spacing: 1.5pt; width: 100%"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK BELI KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 25.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 45.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 67.500,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Ada Pengguna (member) yang ingin membeli coin XYZ sebanyak 10 coin
          dengan harga per coin yang diharapkannya RP. 6.500,-</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Pada saat transaksi beli member diatas dilakukan, karena ada data
          order book jual pada tabel 1 dengan harga jual 1 coin dan jumlah coin
          yang dijual sama dengan yang akan dibeli oleh member, maka transaksi
          langsung berhasil dan tidak masuk kedalam order book dalam
          antrian.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Pada tabel 1 ada 2 data yang matching dengan yang akan dibeli yaitu
          data nomor 2 dan nomor 3. Data diurutkan berdasarkan tanggal dan jam
          (timestamp) yang paling awal masuk, sehingga transaksi yang matching
          adalah transaksi jual nomor 2, sehingga transaksi beli langsung
          berhasil dan data nomor 2 pada tabel 1 terjual duluan dan hilang dari
          daftar antrian jual.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Apabila ada Pengguna (member) yang ingin membeli coin XYZ sebanyak 10
          coin dengan harga per coin Rp. 5.500,- karena angka tersebut tidak ada
          dalam Order Book Jual, maka permintaan tersebut akan masuk ke Order
          Book Beli</span
        >
      </li>
    </ul>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Kondisi Akhir:
    </p>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%; margin-bottom: 10px"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK JUAL KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 32.500,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 70.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 105.000,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify">
      <span style="display: none">&nbsp;</span>
    </p>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%; margin-bottom: 10px"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK BELI KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 55.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 15.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 45.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              6
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 45.000,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Contoh Kasus Jual
    </p>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Kondisi awal
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Sudah ada order book beli dalam antrian, misalnya :</span
        >
      </li>
    </ul>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%; margin-bottom: 10px"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK BELI KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 25.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 45.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 67.500,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify">
      <span style="display: none">&nbsp;</span>
    </p>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%; margin-bottom: 10px"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK JUAL KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 32.500,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 70.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 105.000,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Ada Pengguna yang ingin menjual coin XYZ sebanyak 10 coin dengan
          harga per coin yang diharapkannya RP. 5.000,-</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Pada saat transaksi jual diatas dilakukan, karena ada data order book
          beli pada tabel 1 dengan harga jual 1 coin dan jumlah coin yang dijual
          sama dengan yang akan dijual, maka transaksi langsung berhasil dan
          tidak masuk kedalam order book dalam antrian.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Pada tabel 1 ada 2 data yang matching dengan yang akan dijual yaitu
          data nomor 1 dan nomor 2. Data diurutkan berdasarkan tanggal dan jam
          (timestamp) yang paling awal masuk, sehingga transaksi yang matching
          adalah transaksi jual nomor 2, sehingga transaksi jual langsung
          berhasil dan data nomor 2 pada tabel 1 terjual duluan dan hilang dari
          daftar antrian beli.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Apabila ada Pengguna (member) yang ingin menjual coin XYZ sebanyak 10
          coin dengan harga per coin Rp. 5.500,- karena angka tersebut tidak ada
          dalam Order Book Beli, maka permintaan tersebut akan masuk ke Order
          Book Jual</span
        >
      </li>
    </ul>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Kondisi Akhir
    </p>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK BELI KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 25.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 50.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 45.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 4.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 67.500,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 0pt; margin-bottom: 0pt; text-align: justify">
      <span style="display: none">&nbsp;</span>
    </p>
    <table
      cellspacing="2"
      cellpadding="0"
      style="border-spacing: 1.5pt; width: 100%; margin-top: 10px"
    >
      <thead>
        <tr>
          <td colspan="5" style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
                color: aliceblue;
              "
            >
              ORDER BOOK JUAL KOIN XYZ
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">No</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Nama Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue"
                >Harga Jual 1 Coin yang diinginkan</strong
              >
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Volume Coin</strong>
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p
              style="
                margin-top: 0pt;
                margin-bottom: 0pt;
                text-align: center;
                font-size: 12pt;
              "
            >
              <strong style="color: aliceblue">Jumlah Rupiah</strong>
            </p>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              1
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 5.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 55.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              2
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 32.500,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              3
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              4
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 6.500,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 65.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              5
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              10
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 70.000,-
            </p>
          </td>
        </tr>
        <tr>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              6
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              XYZ
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 7.000,-
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              15
            </p>
          </td>
          <td style="padding: 0.75pt">
            <p style="margin-top: 0pt; margin-bottom: 0pt; font-size: 12pt">
              Rp. 105.000,-
            </p>
          </td>
        </tr>
      </tbody>
    </table>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Metode Transaksi Instan Merupakan metode dalam layanan dimana Verified
      Member dapat melakukan pembelian/penjualan Aset Kripto dengan Rupiah,
      secara instan atau langsung dengan membeli pada titik kesepakatan yang
      terdapat dalam marketplace tanpa perlu menunggu harga menyentuh titik
      nominal yang diinginkan.
    </p>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Jenis aset kripto Aset Kripto yang diperdagangkan dalam platform PT Bursa
      Kripto Indonesia sejumlah 5 jenis aset kripto yang diatur dalam ketentuan
      Peraturan Badan Pengawas Perdagangan Berjangka Komoditi Nomor 11 Tahun
      2022 yaitu:
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Bitcoin (BTC)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Ethereum (ETC)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Dogecoin (DOGE)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Cardano (ADA)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Binance coin (BNB)</span
        >
      </li>
    </ul>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      Tata cara kegiatan transaksi meliputi :
    </h2>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Transaksi Jual / beli
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member login ke dalam system.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Masuk ke menu Market.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Melakukan inisiasi jual/beli.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Sistem melakukan validasi atas kecukupan dana maupun nilai Aset Kripto
        ke database internal PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Jika lolos validasi maka system akan melakukan proses matching
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Untuk transaksi yang matching data akan dicatat dalam database internal
        PT BURSA KRIPTO INDONESIA
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Mengirimkan konfirmasi hasil matching kepada Member melalui
        Website/Aplikasi Trading Platform
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Deposit
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member masuk ke dalam aplikasi web/mobile dengan menggunakan username
        &amp; password.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Masuk ke menu Deposit IDR kemudian memasukkan jumlah rupiah yang akan di
        setorkan, kemudian memilih rekening tujuan transfer kemudian submit
        untuk mendapatkan nominal deposit beserta unique code
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member akan mendapatkan nominal dana, nilai unique code serta rekening
        Bank Tujuan
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member melakukan Setoran via transfer sesuai nominal dana dan unique
        code.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Bagian customer service dan bagian keuangan Perusahaan akan secara
        berkala melakukan pengecekan mutasi pada rekening penampungan dana
        nasabah (rekening PT BURSA KRIPTO INDONESIA) pada masing-masing Bank.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Jika transaksi setoran ditemukan maka bagian customer service akan
        melakukan update ke dalam wallet IDR Member. Member bisa melakukan
        pengecekan untuk memastikan setoran telah masuk dan menambah wallet IDR.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Sistem akan mengirimkan email konfirmasi bahwa Setoran Dana Member telah
        diterima.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Withdrawal
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member login ke dalam system.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Masuk ke menu Withdrawal Aset Kripto, kemudian memasukkan nilai Aset
        Kripto yang akan ditarik dan memilih alamat Wallet Tujuan sesuai yang
        tercatat dalam system. (system akan menolak jika nama pada rekening
        tujuan berbeda dengan nama pemilik akun).
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Sistem melakukan validasi atas kecukupan nilai Aset Kripto yang tersedia
        pada wallet aset Kripto.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Jika sesuai system akan menjalankan perintah transfer Aset Kripto.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Setelah transfer dana selesai dilakukan, system akan mengirimkan email
        konfirmasi kepada email Member Terdaftar.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Pengiriman aset kripto ke wallet lain
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member login ke dalam system.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Masuk ke menu Withdrawal Aset Kripto, kemudian memasukkan nilai Aset
        Kripto yang akan ditarik dan memilih alamat Wallet Tujuan sesuai yang
        tercatat dalam system.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Sistem melakukan validasi atas kecukupan nilai Aset Kripto yang tersedia
        pada wallet aset Kripto.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Jika sesuai system akan menjalankan proses Know Your Transaction dan
        Travel Rule berdasarkan data pengirim dan penerima sesuai dengan aturan
        badan pengawas
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Apabila sudah sesuai, maka system akan melaksanakan transfer aset kripto
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Setelah transfer aset kripto selesai dilakukan, system akan mengirimkan
        email konfirmasi kepada email Member Terdaftar.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Kegiatan lain yang telah mendapat persetujuan dari Bappebti
    </h3>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Saat ini belum ada kegiatan lain yang telah mendapat persetujuan dari
      BAPPEBTI karena kami focus untuk bisnis Perdagangan Aset Kripto.
    </p>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      BIAYA TRANSAKSI DAN BATAS PENARIKAN
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Biaya transaksi dalam PT BURSA KRIPTO INDONESIA sebagai berikut:
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Market Taker/Instant baik untuk transaksi jual maupun transaksi beli
          akan dikenakan 0,51% dari jumlah transaksi</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Market Maker baik untuk transaksi jual maupun transaksi beli akan
          dikenakan 0,11% dari jumlah transaksi.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Biaya transaksi sudah termasuk PPN (Pajak Pertambahan Nilai) sebesar
          0,11% dari jumlah transaksi</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Biaya dapat berubah sewaktu-waktu berdasarkan kebijakan dari PT BURSA
          KRIPTO INDONESIA dengan pemberitahuan terlebih dahulu kepada Pelanggan
          Aset Kripto.</span
        >
      </li>
    </ul>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Batas penarikan dalam PT BURSA KRIPTO INDONESIA untuk sebagai berikut:
    </p>
    <ul type="disc" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Member/Verified Member dengan ini menyatakan setuju untuk mematuhi
          batas penarikan baik terhadap Aset Kripto maupun Rupiah yang berlaku
          terhadap Akun Member/Verified Member, dan PT BURSA KRIPTO INDONESIA
          yang dalam hal ini menerapkan prinsip Anti Money Laundering (AML)
          sesuai dengan regulasi Pemerintah Republik Indonesia diberi kuasa
          serta hak untuk tidak melakukan proses terhadap transaksi yang telah
          melebihi batas penarikan harian yaitu sebesar yang ditentukan oleh PT
          BURSA KRIPTO INDONESIA.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Batas penarikan dana/aset kripto untuk Warga Negara Indonesia (WNI)
          ekuivalen Rp100.000.000,-(Seratus Juta Rupiah)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Batas penarikan dana/asset kripto untuk Warga Negara Asing (WNA)
          ekuivalen Rp10.000.000,- (Sepuluh Juta Rupiah)</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Batas penarikan direset ulang atau kembali pada angka 0 (nol) setiap
          pukul 00.00 Waktu Indonesia Barat (WIB).</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Penambahan batas penarikan dapat dilakukan dengan mengajukan
          permohonan melalui menu penambahan limit pada Website, dimana
          Member/Verified Member wajib melakukan pengisian, pencantuman dan
          pencetakan terhadap semua informasi yang terdapat dalam formulir
          diatas materai Rp. 10.000,- sepuluh ribu rupiah) dan Member/Verified
          Member wajib untuk mengirimkan soft copy terhadap dokumen tersebut
          baik melalui foto atau hasil scan, serta memastikan tulisan dapat
          dibaca dengan jelas, tidak terdapat crop, sensor atau rekayasa digital
          dalam bentuk apa pun; ke email support yang tertera dalam Website
          dengan subjek &acirc;&euro;&oelig;Permohonan Kenaikan Limit
          Penarikan&acirc;&euro;.</span
        >
      </li>
      <li
        style="
          margin-left: 27.6pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 8.4pt;
          font-family: serif;
          font-size: 10pt;
        "
      >
        <span style="font-family: 'Times New Roman'; font-size: 12pt"
          >Proses persetujuan atau penolakan kenaikan batas penarikan limit
          harian mutlak merupakan kewenangan PT BURSA KRIPTO INDONESIA dengan
          pertimbangan dari tim audit dan hukum sesuai permintaan, sejarah
          transaksi, sumber dana, dan tujuan penggunaan transaksi serta tidak
          dapat diintervensi. Member/Verified Member yang ditolak kenaikan
          limitnya baru dapat mengajukan permohonan kembali dengan jangka waktu
          1&Atilde;&mdash;24 (satu kali dua puluh empat) jam.</span
        >
      </li>
    </ul>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      KEAMANAN TRANSAKSI
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      PT BURSA KRIPTO INDONESIA telah menerapkan jaringan dan tindakan kemanan
      sebagai jaringan pengaman informasi terhadap akses yang tidak sah dalam
      penggunaan, perubahan dan/atau pengungkapan Akun, dengan standar keamanan
      yang telah sesuai dengan Ketentuan Peraturan Perundang-undangan yang
      berlaku. Masuknya pihak bertanggung jawab terhadap akses dalam penggunaan,
      perubahan dan/atau pengungkapan Akun dari pihak ketiga akibat kelalaian
      dan/atau kesalahan Member/Verified Member berakibat ditanggungnya risiko
      oleh Member/Verified Member, maka PT BURSA KRIPTO INDONESIA tidak akan
      Memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk apa pun
      kepada Member/Verified Member atau pihak lain manapun atas segala risiko,
      tanggung jawab dan tuntutan apapun yang mungkin timbul sehubungan dengan
      adanya kelalaian/kesengajaan/kesalahan Member/Verified Member dalam
      memberikan informasi.
    </p>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      LAYANAN PENGADUAN PELANGGAN ASET KRIPTO
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Pengaduan Member/Verified Member disini terkait dengan pemberitahuan
      pelanggaran data dan/atau apabila terjadi permasalahan berkenaan dengan
      kepemilikan Aset Kripto yang dimiliki oleh Member/Verified Member. Apabila
      terjadi permasalahan tersebut, berikut mekanisme yang harus dilakukan oleh
      Member/Verified Member antara lain:
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Berkenaan dengan pengaduan Member/Verified Member, Member/Verified
        Member memiliki hak untuk melakukan pemberitahuan kepada PT BURSA KRIPTO
        INDONESIA dengan kondisi sebagai berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila Member/Verified Member menerima spam, segala bentuk iklan
              liar dan/atau surat elektronik yang mengatasnamakan nama selain PT
              BURSA KRIPTO INDONESIA yang meminta data pribadi dan/atau
              mengganggu kenyamanan para Member/Verified Member;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila terdapat praktik tindak kejahatan pencurian dan penipuan
              Aset Kripto yang mengakibatkan hilangnya Aset Kripto di Akun
              Member/Verified Member;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila terdapat dugaan aktivitas pelanggaran data
              Member/Verified Member oleh pihak lain yang bukan dijelaskan pada
              poin a dan b tersebut diatas.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dapat mengajukan pengaduan dengan mengisi
        formulir pengaduan yang disediakan oleh PT BURSA KRIPTO INDONESIA Form
        pengaduan dapat langsung diunduh di menu pengaduan dalam Website yang
        kami sediakan atau dapat diunduh disini;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Berkenaan dengan praktik penyalahgunaan data Member/Verified Member oleh
        pihak lain yang mengakibatkan adanya spam atau praktik skimming, maka
        Member/Verified Member dimohon dapat melakukan pengaduan dengan
        mekanisme sebagai berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Sebagaimana yang diisyaratkan pada poin 2, Member/Verified Member
              dapat melakukan pengaduan melalui pengisian form pengaduan yang
              telah kami sediakan dan dapat mengirimkan ke email support yang
              tertera dalam Website;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Laporan tersebut akan segera kami lakukan tindak lanjut dengan
              penanganan pengaduan dalam kurun waktu 3 x 24 jam;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila dalam kurun waktu tersebut, Member/Verified Member tidak
              mendapatkan pelayanan dari pihak kami, maka Member/Verified Member
              dapat mengirimkan kembali form pengaduan tersebut ke email support
              yang tertera dalam Website dan/atau melakukan kontak melalui
              kantor representatif PT BURSA KRIPTO INDONESIA.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Berkenaan dengan hilangnya Aset Kripto di Akun Member/Verified Member
        sebagai akibat adanya praktik tindak pidana kejahatan elektronik oleh
        pihak lain, maka Member/Verified Member dapat melakukan pengaduan dengan
        mekanisme sebagai berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Sebagaimana yang diisyaratkan pada poin 2, Member/Verified Member
              dapat melakukan pengaduan melalui pengisian form pengaduan yang
              telah disediakan;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Laporan tersebut akan dilaksanakan penangan awal dimana akan
              dilakukan pembekuan Akun oleh pihak terlapor (pihak terduga) yang
              menampung atau mengambil Aset Kripto Member/Verified Member
              tersebut;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Admin akan meneruskan laporan pengaduan anda kepada tim legal
              untuk memproses penanganan selanjutnya. Penanganan selanjutnya
              berupa klarifikasi dengan pihak terlapor (pihak terduga);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila tahap klarifikasi ini berhasil dimana pihak terlapor
              Memberikan respons maka akan dilakukan mediasi dengan pihak
              pelapor (dalam hal ini Member/Verified Member yang melapor);</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Apabila mediasi tidak berhasil maka akan dilanjutkan melalui
              laporan ke kepolisian setempat dan kami akan mempersiapkan data
              yang diminta.</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Layanan pengaduan nasabah dapat dikirim melalui surat elektronik ataupun
        melalui chat kepada alamat sebagai berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Email :&nbsp;</span
            ><span
              style="font-family: 'Times New Roman'; font-size: 12pt"
              >contact@bursakriptoindonesia.com</span>
          </li>
          <li
            style="
              margin-left: 29pt;
              margin-bottom: 14pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Telpn : 021 50101858</span
            >
          </li>
        </ul>
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Layanan Pendukung Website atau Aplikasi
    </h3>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      PT BURSA KRIPTO INDONESIA menyediakan layanan dalam bentuk Website dengan
      ketentuan sebagai berikut:
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam perihal penggunaan Website oleh Member/Verified Member dan
        pengguna, PT BURSA KRIPTO INDONESIA berhak untuk meminta dan
        mengumpulkan informasi berkenaan dengan perangkat seluler yang digunakan
        Member/Verified Member untuk mengakses Website yang disediakan termasuk
        namun tidak terbatas untuk perangkat keras, sistem operasi, pengenal
        perangkat unik, informasi jaringan seluler juga termasuk data pribadi
        seperti alamat surat elektronik, alamat, nomor telepon seluler, alias,
        kata sandi, kode PIN Sellerdan informasi lainnya yang diperlukan untuk
        menggunakan layanan Website ini;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Website ini telah diuji oleh para peneliti dan Pengembang PT BURSA
        KRIPTO INDONESIA dan didalam penggunaannya, PT BURSA KRIPTO INDONESIA
        menghimbau untuk berhati-hati didalam pemakaiannya dan PT BURSA KRIPTO
        INDONESIA tidak dapat menjamin performa dan Website ini selalu berjalan
        dengan hasil yang dikehendaki;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA dan Pengembang tidak bertanggung jawab dan
        tidak terikat untuk segala bentuk keuntungan maupun kerugian yang
        dimungkinkan terjadi sebagai akibat dari penggunaan Website ini.
        Keuntungan yang dimaksud mengacu pada bertambahnya jumlah saldo dalam
        bentuk format Aset Kripto ataupun Rupiah Sedangkan untuk kerugian yang
        dimaksud, hal tersebut termasuk namun tidak terbatas untuk: berkurangnya
        jumlah saldo dalam format Aset Kripto dan/atau Rupiah, kegagalan untuk
        menjalankan API dan segala perintahnya, masalah jaringan/sinyal,
        terjadinya error pada sistem atau error yang disebabkan karena
        faktor-faktor dalam bentuk lain;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Dalam pemakaian Website ini, Member/Verified Member ataupun Pengguna
        telah menyetujui bahwa dalam kondisi apa pun, Member/Verified Member
        dilarang untuk: menggandakan, melakukan penyalinan, memproduksi ulang,
        menerjemahkan, mengganti sistem, memodifikasi, melepas pemasangan,
        melepas susunan atau mencoba untuk menderivasikan kode sumber dari
        produk perangkat lunak ini;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Segala keputusan di dalam penggunaan Website ini adalah keputusan secara
        suka rela atau independen yang dibuat oleh Member/Verified Member
        ataupun Pengguna tanpa adanya paksaan dari PT BURSA KRIPTO INDONESIA dan
        Pengembang. Atas perihal ini, Member/Verified Member ataupun Pengguna
        melepaskan PT BURSA KRIPTO INDONESIA dan Pengembang dari segala bentuk
        tuntutan, ganti rugi dan segala tanggung jawab dalam bentuk apa pun;
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member atau Pengguna menyatakan telah mengerti
        batasan-batasan keamanan dan privasi namun tidak terbatas kepada: (i)
        batasan ukuran dan fitur keamanan, privasi, dan autentikasi dalam
        layanan; dan (ii) segala data dan informasi di dalam layanan mungkin
        dapat mengarah ke penyadapan, pemalsuan, spam, sabotase, pembajakan kata
        sandi, gangguan, penipuan, penyalahgunaan elektronik, peretasan, dan
        kontaminasi sistem, termasuk namun tanpa pembatasan, virus, worms, dan
        Trojan horses, yang menyebabkan ketidakabsahan, kerusakan, maupun akses
        yang berbahaya, dan/atau pemulihan informasi atau data dalam komputer
        Member/Verified Member atau bahaya keamanan dan privasi lainnya. Apabila
        Member/Verified Member tidak menghendaki untuk dikenai risiko-risiko
        tersebut, Member/Verified Member disarankan untuk tidak penggunaan
        Website maupun layanan ini.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Kerjasama Layanan
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Demi memudahkan transaksi, dan membangun komunitas Aset Kripto di
        Indonesia, PT BURSA KRIPTO INDONESIA bekerja sama dengan beberapa
        perusahaan internasional yang juga bergerak di dunia Aset Kripto dan
        blockchain. Partner resmi PT BURSA KRIPTO INDONESIA terdapat pada link
        berikut ini.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Demi memudahkan dan untuk partner resmi Nasional kami terdapat pada link
        ini.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA tidak bekerjasama dalam hal apapun dengan
        perusahaanperusahaan Arisan Berantai, cloud mining, MLM, Ponzi Scheme,
        Money Game dan HYIP yang menawarkan profit dari perdagangan atau
        penggunaan Aset Kripto, dan tidak bekerja sama dengan Biclubnetwork,
        BTCPanda, MMM Global, MMM Indonesia, Onecoin, Binary, BITCOINTRUST2U,
        BTCPANDA, BITKINGDOM, BITCLUBNETWORK, MMM GLOBAL, MMM Indonesia,
        EUROBIT, ILGAMOS, FUTURENET, E-DINAR dan tidak bertanggung jawab atas
        penggunaan layanan tersebut diatas oleh Member/Verified Member;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Perusahaan yang tidak terdata didalam Website dapat diartikan sebagai
        tidak maupun belum bekerja sama dengan PT BURSA KRIPTO INDONESIA. PT
        BURSA KRIPTO INDONESIA tidak merekomendasi perusahaan mana pun termasuk
        perusahaan yang bekerja sama dengan PT BURSA KRIPTO INDONESIA. Segala
        keputusan untuk menggunakan layanan tersebut menjadi tanggung jawab
        masing-masing Pengguna.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        PT BURSA KRIPTO INDONESIA dengan ini menjelaskan bahwa tidak memiliki
        afiliasi resmi dengan partner yang telah disebutkan pada ayat (3)
        diatas. Segala transaksi yang terjadi antara Member/Verified Member
        dengan perusahaanperusahaan di atas bukan merupakan tanggung jawab PT
        BURSA KRIPTO INDONESIA.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.10. PENYELESAIAN PERSELISIHAN PELANGGAN ASET KRIPTO
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      Setiap perselisihan, sengketa atau perbedaan pendapat (selanjutnya disebut
      sebagai Perselisihan) yang timbul sehubungan dengan pelaksanaan kerja sama
      akan diselesaikan dengan cara sebagai berikut:
    </p>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Bahwa setiap Perselisihan, sepanjang memungkinkan, akan diselesaikan
        dengan cara musyawarah untuk mufakat sebagaimana diatur dalam perjanjian
        antar para pihak atau peraturan Bappebti.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Setiap Perselisihan yang tidak dapat diselesaikan secara musyawarah,
        akan diselesaikan melalui Badan Arbitrase Perdagangan Berjangka Komoditi
        (BAKTI) atau Pengadilan Negeri Jakarta Selatan.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.11. KEADAAN KAHAR/FORCE MAJEURE
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Yang dimaksud dengan Force Majeure adalah kejadian-kejadian yang terjadi
        di luar kemampuan dan kekuasaan PT BURSA KRIPTO INDONESIA sehingga
        mempengaruhi pelaksanaan transaksi antara lain namun tidak terbatas
        pada:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Gempa bumi, angin topan, banjir, tanah longsor, gunung meletus
              dan bencana alam lainnya;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Perang, demonstrasi, huru-hara, terorisme, sabotase, embargo, dan
              pemogokan massal;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Kebijakan ekonomi dari Pemerintah yang mempengaruhi secara
              langsung;</span
            >
          </li>
        </ul>
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Sepanjang PT BURSA KRIPTO INDONESIA telah melaksanakan segala
        kewajibannya sesuai dengan peraturan perundang-undangan yang berlaku
        sehubungan dengan terjadinya Force Majeure tersebut, maka PT BURSA
        KRIPTO INDONESIA tidak akan memberikan ganti rugi dan atau
        pertanggungjawaban dalam bentuk apapun kepada Member/Verified Member
        atau pihak lain mana pun atas segala risiko, tanggung jawab dan tuntutan
        apa pun yang mungkin timbul sehubungan dengan keterlambatan maupun tidak
        dapat dilaksanakannya kewajiban akibat Force Majeure.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.12. PENERAPAN PROGRAM ANTI PENCUCIAN UANG DAN PENCEGAHAN PENDANAAN
      TERORISME (APU/PPT) SERTA POLIFERASI SENJATA PEMUSNAH MASAL
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam hal terjadinya transaksi mencurigakan yang dilakukan melalui
        layanan PT BURSA KRIPTO INDONESIA, maka PT BURSA KRIPTO INDONESIA berhak
        untuk menghentikan/menonaktifkan Akun PT BURSA KRIPTO INDONESIA pada
        Member/Verified Member dan memblokir dana transaksi serta melakukan
        penundaan transaksi kepada Member/Verified Member, sampai dengan adanya
        pemberitahuan dari PT BURSA KRIPTO INDONESIA
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Dalam hal terjadi transaksi mencurigakan dan/atau transaksi yang
        melebihi batasan volume transaksi yang ditetapkan oleh PT BURSA KRIPTO
        INDONESIA terhadap Member/Verified Member karena alasan apapun juga,
        maka PT BURSA KRIPTO INDONESIA berhak sewaktu-waktu menunda pengkreditan
        dana ke Akun PT BURSA KRIPTO INDONESIA Member/Verified Member dan/atau
        melakukan pemblokiran Akun Member/Verified Member sampai proses
        investigasi selesai dilakukan dalam jangka waktu yang ditentukan oleh PT
        BURSA KRIPTO INDONESIA.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Apabila terbukti bahwa transaksi tersebut pada angka 1 dan 2 tersebut
        diatas mengenai transaksi mencurigakan adalah transaksi yang melanggar
        PTT dan/atau peraturan perundang-undangan yang berlaku, maka
        Member/Verified Member dengan ini Memberi kuasa kepada PT BURSA KRIPTO
        INDONESIA untuk mendebet Aset Kripto pada Dompet Digital PT BURSA KRIPTO
        INDONESIA untuk mengganti kerugian PT BURSA KRIPTO INDONESIA yang timbul
        akibat transaksi tersebut, tanpa mengurangi hak untuk melakukan tuntutan
        ganti rugi atas seluruh kerugian yang timbul akibat transaksi tersebut
        dan Member/Verified Member dengan ini setuju bahwa PT BURSA KRIPTO
        INDONESIA tidak wajib melakukan pengembalian atas dana yang ditunda
        pengkreditannya oleh PT BURSA KRIPTO INDONESIA atau dana yang diblokir
        sebagaimana dimaksud pada angka 2 ketentuan mengenai transaksi
        mencurigakan ini.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      Pemblokiran dan Pembekuan Akun Member/Verified Member:
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Dalam rangka menjalankan prinsip kehati-hatian, PT BURSA KRIPTO
        INDONESIA berhak dan Member/Verified Member dengan ini memberi kuasa
        kepada PT BURSA KRIPTO INDONESIA untuk memblokir baik sebagian atau
        seluruh saldo Member/Verified Member (hold amount) dan/atau mendebetnya
        serta melakukan pemberhentian Akun, apabila terjadi hal berikut:
        <ul
          type="circle"
          style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
        >
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Adanya permintaan dari pihak perbankan dikarenakan adanya
              kesalahan pengiriman dana dan pihak perbankan tersebut meminta
              dilakukan pemblokiran;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >Menurut pendapat dan pertimbangan PT BURSA KRIPTO INDONESIA
              terdapat kesalahan penerimaan transaksi;</span
            >
          </li>
          <li
            style="
              margin-left: 29pt;
              padding-left: 7pt;
              font-family: serif;
              font-size: 10pt;
            "
          >
            <span style="font-family: 'Times New Roman'; font-size: 12pt"
              >PT BURSA KRIPTO INDONESIA digunakan sebagai tempat penampungan
              yang diindikasikan hasil kejahatan termasuk namun tidak terbatas
              pada Tindak Pidana korupsi, penyuapan, narkotika, psikotropika,
              penyelundupan tenaga kerja, penyelundupan manusia, bidang
              perbankan, bidang pasar modal, bidang perasuransian, kepabeanan,
              cukai, perdagangan manusia, perdagangan senjata gelap, terorisme,
              penculikan, pencurian, penggelapan, penipuan, pemalsuan,
              perjudian, prostitusi, bidang perpajakan, dan terorisme;</span
            >
          </li>
        </ul>
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member dinilai lalai dalam melakukan kewajiban
        berdasarkan PTT;
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Kebijakan PT BURSA KRIPTO INDONESIA atau oleh suatu ketetapan pemerintah
        atau instansi yang berwenang atau peraturan yang berlaku, sehingga PT
        BURSA KRIPTO INDONESIA diharuskan melakukan pemblokiran dan atau
        pembekuan Akun PT BURSA KRIPTO INDONESIA tersebut.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Apabila terjadi pemberhentian Akun, Member/Verified Member setuju dan
        menyatakan bersedia untuk tetap terikat dengan PTT menghentikan
        penggunaan layanan PT BURSA KRIPTO INDONESIA, memberikan hak kepada
        Website/Trading Platform PT BURSA KRIPTO INDONESIA untuk menghapus semua
        informasi dan data dalam server PT BURSA KRIPTO INDONESIA, dan
        menyatakan PT BURSA KRIPTO INDONESIA tidak bertanggung jawab kepada
        Member/Verified Member atau Pihak Ketiga atas penghentian akses dan
        penghapusan informasi serta data dalam Akun Member/Verified Member.
      </li>
    </ol>
    <h3
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 13.5pt;
      "
    >
      Penolakan, Penundaan dan Pembatalan Transaksi Mencurigakan
    </h3>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        PT BURSA KRIPTO INDONESIA berhak untuk melakukan penundaan dan/atau
        penolakan transaksi apabila PT BURSA KRIPTO INDONESIA mengetahui atau
        berdasarkan pertimbangan, menduga bahwa kegiatan penipuan dan/atau aksi
        kejahatan telah dan/atau akan dilakukan
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member setuju dan mengakui bahwa sepanjang diperbolehkan
        oleh ketentuan Hukum yang berlaku, PT BURSA KRIPTO INDONESIA wajib
        menolak untuk memproses segala transaksi.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member mengakui bahwa PT BURSA KRIPTO INDONESIA tunduk
        kepada Undang-Undang tentang kejahatan keuangan, termasuk namun tidak
        terbatas pada, Undang-Undang Pemberantasan Tindak Pidana Korupsi dan
        Undang-Undang Tindak Pidana Pencucian Uang yang berlaku di Indonesia dan
        secara internasional, segala peraturan perundang-undangan yang berlaku
        di Indonesia dan kebijakan internal PT BURSA KRIPTO INDONESIA Untuk
        tujuan tersebut, Member/Verified Member dengan ini setuju untuk
        memberikan segala informasi yang diminta oleh PT BURSA KRIPTO INDONESIA
        guna memenuhi peraturan perundang-undangan tersebut termasuk namun tidak
        terbatas pada nama, alamat, usia, jenis kelamin, keterangan identitas
        pribadi, pendapatan, pekerjaan, harta kekayaan, hutang, sumber kekayaan,
        tujuan pembukaan Akun, tujuan investasi, segala rencana keuangan atau
        informasi keuangan terkait lainnya dari Member/Verified Member. Jika
        diperlukan oleh PT BURSA KRIPTO INDONESIA, Member/Verified Member juga
        setuju untuk menyediakan data terbaru tentang informasi tersebut kepada
        PT BURSA KRIPTO INDONESIA.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA berkewajiban untuk mematuhi hukum, peraturan
        dan permintaan lembaga masyarakat dan pemerintah dalam yurisdiksi yang
        berbeda-beda yang berkaitan dengan pencegahan atas pembiayaan untuk,
        antara lain, teroris dan pihak yang terkena sanksi. Hal ini dapat
        menyebabkan PT BURSA KRIPTO INDONESIA untuk melakukan pencegatan dan
        menyelidiki segala perintah pembayaran dan informasi atau komunikasi
        lainnya yang dikirimkan kepada atau oleh Member/Verified Member, atau
        atas nama Member/Verified Member melalui sistem PT BURSA KRIPTO
        INDONESIA Proses ini juga dapat melibatkan PT BURSA KRIPTO INDONESIA
        untuk melakukan penyelidikan lebih lanjut untuk menentukan apakah nama
        yang muncul dalam segala transaksi yang dilakukan atau akan dilakukan
        oleh Member/Verified Member melalui Akunnya adalah nama teroris.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA tidak akan bertanggung jawab untuk setiap
        kerugian (baik secara langsung dan termasuk namun tidak terbatas pada
        kehilangan keuntungan atau bunga) atau kerugian yang diderita oleh pihak
        manapun yang timbul dari segala penundaan atau kelalaian dari PT BURSA
        KRIPTO INDONESIA untuk memproses segala perintah pembayaran tersebut
        atau informasi atau komunikasi lainnya atau untuk melaksanakan segala
        kewajiban lainnya, yang disebabkan secara keseluruhan atau sebagian oleh
        segala tindakan yang diambil berdasarkan angka 4 ketentuan Penolakan dan
        Penundaan Transaksi ini.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        PT BURSA KRIPTO INDONESIA berwenang untuk melakukan pemantauan atas Akun
        Member/Verified Member dalam rangka pencegahan kejahatan keuangan.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member mengerti, memahami dan setuju bahwa terhadap
        setiap transaksi yang telah dilakukan melalui PT BURSA KRIPTO INDONESIA
        bersifat final dan tidak dapat dilakukan pembatalan oleh Member/Verified
        Member.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.13. PENYAMPAIAN SYARAT DAN KETENTUAN DALAM HAL CALON PEDAGANG FISIK ASET
      KRIPTO ATAU PEDAGANG FISIK ASET KRIPTO MENGAMBIL POSISI UNTUK DIRI SENDIRI
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      PT Bursa Kripto Indonesia tidak mengambil posisi untuk diri sendiri
    </p>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.14. PEMBERITAHUAN
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member menyatakan setuju untuk berkomunikasi dengan PT
        BURSA KRIPTO INDONESIA dalam format elektronik, dan menyetujui bahwa
        semua syarat, kondisi, perjanjian, pemberitahuan, pengungkapan atau
        segala bentuk komunikasi lainnya yang disediakan oleh PT BURSA KRIPTO
        INDONESIA kepada Member/Verified Member secara elektronik dianggap
        sebagai tertulis.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Member/Verified Member menyatakan setuju untuk menerima email dari
        Website. E-mailyang dikirim dapat berisi informasi seputar Akun,
        transaksi, sistem, promosi dan lain sebagainya.
      </li>
    </ol>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      6.15. DOMISILI HUKUM
    </h2>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      PTT dibuat, ditafsirkan dan diberlakukan berdasarkan hukum negara Republik
      Indonesia. Website/Trading Platform PT BURSA KRIPTO INDONESIA dan
      Member/Verified Member telah sepakat untuk memilih tempat kediaman hukum
      yang umum dan tidak berubah pada Kantor Kepaniteraan Pengadilan Negeri
      Jakarta Selatan.
    </p>
    <h2
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: justify;
        font-size: 18pt;
      "
    >
      LAIN-LAIN
    </h2>
    <ol type="1" style="margin: 0pt; padding-left: 0pt">
      <li
        style="
          margin-top: 14pt;
          margin-left: 32pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        PTT ini tunduk dan diberlakukan berdasarkan pada UU No 8 Tahun 2010
        tentang Tindak Pidana Pencucian Uang dan Peraturan Kepala PPATK
        PER-09/1.02.2/PPATK/09/12,No. PER-12/1.02/PPATK/06/13, No.
        PER-12/1.02.1/PPATK/09/11 dan No. PER-02/1.02/PPATK/02/2014. Oleh
        karenannya, segala transaksi dengan nominal diatas Rp 100,000,000,-
        (seratus juta rupiah) diwajibkan adanya pelaporan transaksi tersebut
        kepada pihak PPATK.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Untuk hal-hal yang belum diatur dalam PTT, maka akan berlaku seluruh
        ketentuan dalam Kitab Undang-Undang Hukum Perdata serta ketentuan-
        ketentuan peraturan perundang-undangan lainnya yang terkait.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Jika PT BURSA KRIPTO INDONESIA melakukan perubahan terhadap isi PTT ini
        maka PT BURSA KRIPTO INDONESIA akan memberitahukan perubahannya kepada
        Member/Verified Member sesuai dengan peraturan perundang- undangan yang
        berlaku melalui media pemberitahuan yang dianggap baik oleh PT BURSA
        KRIPTO INDONESIA dan selanjutnya Member/Verified Member akan tunduk pada
        perubahan PTT tersebut. Perubahan setiap lampiran dari PTT akan
        disepakati dan untuk selanjutnya merupakan satu kesatuan dengan dan
        merupakan bagian yang tidak terpisahkan dari PTT.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Apabila Member/Verified Member melakukan tindakan-tindakan di luar
        ketentuan PTT, maka Member/Verified Member akan bertanggung jawab
        sepenuhnya dan dengan ini setuju bahwa PT BURSA KRIPTO INDONESIA tidak
        akan memberikan ganti rugi dan atau pertanggungjawaban dalam bentuk
        apapun kepada Member/Verified Member, atau pihak manapun atas segala
        tuntutan dan atau gugatan dan atau klaim yang diajukan pihak lain
        sehubungan dengan tindakan-tindakan yang dilakukan Member/Verified
        Member.
      </li>
      <li style="margin-left: 32pt; text-align: justify; padding-left: 4pt">
        Member/Verified Member wajib mematuhi seluruh persyaratan yang
        dicantumkan di dalam PTT. Kelalaian Member/Verified Member didalam
        mentaati atau melaksanakan isi dari PTT pada satu atau beberapa kali
        kejadian, tidak akan menghilangkan kewajiban Member/Verified Member
        untuk tetap memenuhi segala persyaratan yang terdapat di dalam PTT.
      </li>
      <li
        style="
          margin-left: 32pt;
          margin-bottom: 14pt;
          text-align: justify;
          padding-left: 4pt;
        "
      >
        Pertanyaan seputar Ketentuan dan Persyaratan atau perihal lain,
        dilakukan melalui email support yang tertera dalam Website.
      </li>
    </ol>
    <p style="margin-top: 14pt; margin-bottom: 14pt; text-align: justify">
      <em
        >*SYARAT DAN KETENTUAN UMUM MEMBER/VERIFIED MEMBER WEBSITE/TRADING
        PLATFORM PT BURSA KRIPTO INDONESIA INI TELAH DISESUIKAN DENGAN KETENTUAN
        PERATURAN PERUNDANG-UNDANGAN YANG BERLAKU</em
      >
    </p>
  </div>
</template>

<script>
// node_modules

// applibs

export default {
  components: {},
  data() {
    return {
      web_domain: "bursakriptoindonesia.com",
    };
  },
};
</script>

<style scoped>
.text_h {
  list-style-type: none;
  margin-left: -20px;
  padding-top: 20px;
}

h2 {
  font-size: 2.5rem;
}
.txt_p,
.text_h,
h1,
h2,
strong {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}

li,
p {
  font-size: 16px;
}

.ctr_trm {
  max-width: 932px;
  padding: 0 20px;
  z-index: 995;
}

.head_txt {
  font-size: 28px;
  margin-left: -20px;
}

.disclaimer {
  padding: 20px;
  margin-top: 40px;
  background-color: #fff3cd;
  color: gray;
}

@media (max-width: 800px) {
  h5.title {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .txt_p,
  .text_h,
  .head_txt,
  h1,
  h2,
  strong {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }

  li,
  p {
    font-size: 14px;
  }
}
</style>
