<template>
  <div>
    <TopNav />

    <div class="settings mtb15 text-light">
      <div class="container text-center">
        <div class="row mb-4 mt-4">
          <div class="col">
            <h1>Persetujuan</h1>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="text-light text-center">
              <div class="wrapper">
                <p>
                  Sesuai Peraturan Badan Pengawas Perdagangan Berjangka Komoditi
                  <strong>No. 8 Tahun 2021 </strong> tentang Pedoman
                  Penyelenggaraan Perdagangan Pasar Fisik Aset Kripto
                  <strong>Pasal 27 ayat (5)</strong>. Kami akan memperhatikan
                  data pengguna untuk disetujui sebagai syarat KYC.
                </p>

                <ul id="partner" class="text-center">
                  <li>
                    <a
                      href="https://bappebti.go.id/calon_pedagang_aset_kripto"
                      target="blank"
                    >
                      <div
                        style="
                          background-color: #fff;
                          border-radius: 10px;
                          padding: 10px;
                        "
                      >
                        <img
                          src="https://bappebti.go.id/assets/public/images/logo/logo.png"
                          alt=""
                          class="logo-bappebti"
                        />
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="agreement-check row mt-5 mx-auto">
              <form @submit.prevent="submitForm">
                <div class="row mt-4">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                      type="checkbox"
                      name="agree"
                      required
                      class="custom-control-input"
                      id="form-checkbox"
                    />
                    <label class="custom-control-label" for="form-checkbox">
                      Saya telah membaca dan menyetujui dan mengisikan data yang
                      sebenarnya
                    </label>
                  </div>
                </div>

                <div class="row">
                  <button class="btn btn-gradient">Mulai</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";

export default {
  components: {
    TopNav,
    Footer,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
      namaAppB: process.env.VUE_APP_NAMA_APP_B,
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;
    } else {
      self.$router.push("/");
    }

    // checking KYC step
    await self.kycChecing();
  },

  methods: {
    async kycChecing() {
      var self = this;
      var res = await thestore.postKycCheckingStep(self.token);
      if (res.kyc_step != 0) {
        if (res.kyc_type == "Indonesia") {
          if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/id/dua");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/id/tiga");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/id/empat");
          }
        } else {
          if (res.kyc_step == 1) {
            // selesai 1
            self.$router.push("/kyc/global/dua");
          } else if (res.kyc_step == 2) {
            // selesai 2
            self.$router.push("/kyc/global/tiga");
          } else if (res.kyc_step == 3) {
            // selesai 3
            self.$router.push("/kyc/global/empat");
          }
        }
      }
    },
    async submitForm() {
      var self = this;
      var res = await thestore.postKycGlobalPersetujuan(self.token);
      // console.log("res", res);
      if (res.success == true) {
        self.$router.push("/kyc/global/dua");
      } else {
        alert(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.logo-bappebti {
  max-width: 200px;
}
</style>
