import { createApp } from "vue";

// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.min.js";
// import "./assets/css/responsive.css";

import router from "./router";
import store from "./store";
// import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import App from "./App.vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import VueIframe from "vue-iframes";

// import VCalendar from "v-calendar";

import VueSocketIO from "vue-3-socket.io";
// import SocketIO from 'vue-3-socket.io';

import Notifications from "@kyvg/vue3-notification";
import VueNumberFormat from "vue-number-format";

import mitt from "mitt";
const emitter = mitt();

library.add(fas, far, fab);

const app = createApp(App);

app.config.globalProperties.emitter = emitter;

app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 10,
  newestOnTop: true,
});
app.component("fa", FontAwesomeIcon);

// karena semua yang ada pada .env adalah string, kita harus convert dulu
var socketio_debug = true;
if (process.env.VUE_APP_SOCKETIO_DEBUG == "true") {
  socketio_debug = true;
} else {
  socketio_debug = false;
}

app.use(store);
app.use(router);
app.use(VueIframe);
// app.use(VCalendar);
app.use(VueNumberFormat, { prefix: "", decimal: ",", thousand: "." });
app.use(
  new VueSocketIO({
    debug: socketio_debug,
    // connection: SocketIO(process.env.VUE_APP_WS)
    connection: process.env.VUE_APP_WS,
  })
);
app.use(Notifications);
app.mount("#app");
